import React, { useState } from "react";

const PhDosingCalculator = () => {
  const [inletFlow, setInletFlow] = useState(0);
  const [inletPh, setInletPh] = useState("");
  const [selectedChemical, setSelectedChemical] = useState("");
  const [purity, setPurity] = useState(0);
  const [output, setOutput] = useState({
    massPerDay: null,
    solution: null,
    tankCapacity: null,
    dosingPump: null,
  });

  const chemicals = {
    Acid: ["Acid-HCl", "Acid-H2SO4"],
    Base: ["Lime-Ca(OH)2", "Caustic-NaOH"],
  };

  const handleCalculate = () => {
    const outletPh = 7;
    const requiredChemical = inletPh < 7 ? "Base" : "Acid";
    const hydrogenConcentrationInitial = Math.pow(10, -inletPh);
    const hydrogenConcentrationTarget = Math.pow(10, -outletPh);
    const hydrogenIonsToNeutralize =
      inletPh < 7
        ? (hydrogenConcentrationInitial - hydrogenConcentrationTarget) *
          inletFlow *
          1000
        : (hydrogenConcentrationTarget - hydrogenConcentrationInitial) *
          inletFlow *
          1000;

    const chemicalMoles =
      selectedChemical === "Lime-Ca(OH)2"
        ? 74.09
        : selectedChemical === "Caustic-NaOH"
        ? 40
        : selectedChemical === "Acid-HCl"
        ? 36.46
        : selectedChemical === "Acid-H2SO4"
        ? 98
        : 0;

    const massPerHourGM = hydrogenIonsToNeutralize * chemicalMoles;
    const massPerHour = massPerHourGM / 1000;
    const massPerDay = (massPerHour * 24) / purity;
    const solution = massPerDay * 10;
    const tankCapacityTemp = massPerDay + solution;

    function roundToNextMultipleOfTen(value) {
      if (value === 0) return 0;
      return Math.ceil(value / 10) * 10;
    }

    const tankCapacity = roundToNextMultipleOfTen(tankCapacityTemp);
    const dosingPump = tankCapacity / 20;

    setOutput({
      hydrogenConcentrationInitial: hydrogenConcentrationInitial,
      hydrogenConcentrationTarget: hydrogenConcentrationTarget,
      hydrogenIonsToNeutralize: hydrogenIonsToNeutralize,
      chemicalMoles: chemicalMoles,
      massPerHour: massPerHour,
      massPerHourGM: massPerHourGM,

      massPerDay: massPerDay.toFixed(5),
      solution: solution.toFixed(5),
      tankCapacity: tankCapacity.toFixed(5),
      dosingPump: dosingPump.toFixed(5),
    });
  };

  return (
    <div className="ph-calculator">
      <h1>pH Dosing Calculator</h1>

      <div className="input-section">
        <div>
          <label>Inlet Flow (m3/hr):</label>
          <input
            type="number"
            value={inletFlow}
            onChange={(e) => setInletFlow(Number(e.target.value))}
            placeholder="0"
          />
        </div>
        <div>
          <label>Inlet pH:</label>
          <input
            type="number"
            value={inletPh}
            onChange={(e) => setInletPh(Number(e.target.value))}
            min={0}
            max={14}
            placeholder="Enter pH (0-14)"
          />
        </div>
        <div>
          <label>Outlet pH:</label>
          <input type="number" value={7} readOnly />
        </div>
        <div>
          <label>Required Chemical:</label>
          <input type="text" value={inletPh < 7 ? "Base" : "Acid"} readOnly />
        </div>
        {inletPh !== "" && (
          <div>
            <label>Select Chemical:</label>
            <select
              value={selectedChemical}
              onChange={(e) => setSelectedChemical(e.target.value)}
            >
              {chemicals[inletPh < 7 ? "Base" : "Acid"].map((chemical) => (
                <option key={chemical} value={chemical}>
                  {chemical}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label>Purity of Chemical (%):</label>
          <input
            type="number"
            value={purity}
            min={0.1}
            step={0.1}
            onChange={(e) => setPurity(Number(e.target.value))}
          />
        </div>
        <button onClick={handleCalculate}>Calculate</button>
      </div>

      {output.massPerDay && (
        <div className="output-section">
          <h2>Calculation Results</h2>
          <p>
            hydrogenConcentrationInitial: {output.hydrogenConcentrationInitial},
          </p>
          <p>
            hydrogenConcentrationTarget: {output.hydrogenConcentrationTarget},
          </p>
          <p>hydrogenIonsToNeutralize: {output.hydrogenIonsToNeutralize},</p>
          <p>chemicalMoles: {output.chemicalMoles},</p>
          <p>massPerHour: {output.massPerHour},</p>
          <p>massPerHourGM: {output.massPerHourGM},</p>

          <p>Mass of Chemical Required per Day: {output.massPerDay} Kg</p>
          <p>Making 10% Solution: {output.solution} Liters/Day</p>
          <p>Tank Capacity (1 Day): {output.tankCapacity} Liters</p>
          <p>Dosing Pump Capacity: {output.dosingPump} LPH</p>
        </div>
      )}

      <div className="chemical-table">
        <h2>Pros and Cons</h2>
        {selectedChemical === "Lime-Ca(OH)2" && (
          <div>
            <p>Pros: 1. Cost-effective, 2. Commonly available</p>
            <p>Cons: 1. Slower to react, 2. More handling precautions</p>
          </div>
        )}
        {selectedChemical === "Caustic-NaOH" && (
          <div>
            <p>Pros: 1. Highly reactive, 2. Quick adjustment of pH</p>
            <p>Cons: 1. More hazardous, 2. Expensive</p>
          </div>
        )}
        {selectedChemical === "Acid-HCl" && (
          <div>
            <p>Pros: 1. Cost-effective, 2. Commonly available</p>
            <p>Cons: 1. Slower to react, 2. More handling precautions</p>
          </div>
        )}
        {selectedChemical === "Acid-H2SO4" && (
          <div>
            <p>Pros: 1. Highly reactive, 2. Quick adjustment of pH</p>
            <p>Cons: 1. More hazardous, 2. Expensive</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhDosingCalculator;

import React, { createContext, useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { AppBar, Tabs, Tab, Container, Box } from "@mui/material";
import Navbar from "../component/navbar/Navbar";
import Weather from "./weather/Weather";
import CityContext from "../context/CityContext";
import Blog from "./blog/Blog";
import CreatePost from "./blog/pages/createpost/CreatePost";
import AirQuality from "./airquality/AirQuality";
import PostPage from "./blog/pages/postpage/PostPage";
import EditPost from "./blog/pages/editpost/EditPost";
import News from "./news/News";
import Community from "./community/Community";
import Login from "./login/Login";
import Register from "./register/Register";
import Homepage from "./home/Homepage";
import Footer from "../component/footer/Footer";
import Searchpage from "./searchpage/Searchpage";
import Dashboard from "./dashboard/Dashboard";
import PrivateRoute from "../component/PrivateRoute";
import AdminRoute from "../component/onlyAdminPrivateRoute";
import AboutUs from "./AboutUS/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import Readings from "./Readings/Readings";
import PrivacypolicyPage from "./Privacypolicy/Privacypolicy";
import Updates from "./newUpdates/Updates";
import Showevent from "../component/homepageComponent/Event/Showevent";
import ContributorPost from "../component/contributorPost/ContributorPost";
import EditContributorPost from "./blog/pages/editpost/EditContributorPost";
import EventPage from "../component/eventpage/EventPage";
import Events from "../component/homepageComponent/Event/Events";
import ProductPage from "./product/ProductPage";
import ReviewPost from "./blog/pages/editpost/ReviewPost";
import NotFound from "./404";
import Terms from "./extra/Terms";
import Forget from "./login/Forget";
import ResetPassword from "./login/Resetpage";
import Cal from "./calculator/cal";
import Calculator from "./calculator/Calculator";


// Import new calculator components
import WaterConsumptionCalculator from "../page/calculator/components/WaterConsumptionCalculator";
import TDSBlendingCalculator from "../page/calculator/components/TDSBlendingCalculator";
import PipelineSizeCalculator from "../page/calculator/components/PipelineSizeCalculator";
import AirRequirementCalculator from "../page/calculator/components/AirRequirementCalculator";
import PHCalculator from "../page/calculator/components/PHCalculator";
import AQICalculator from "../page/calculator/components/AQICalculator";

export const WeatherImageContext = createContext();
export const SearchpostContext = createContext();
export const SearchContext = createContext();
export const cityContext = createContext();
export const aqiDataContext = createContext();

export default function Home() {
  const [newCity, setNewCity] = useState("surat");
  const [location, setLocation] = useState("");
  const [search, setSearch] = useState("");
  const [aqiData, setAqiData] = useState("surat");
  const [airData, setAirData] = useState({
    imageUrl: "",
    aqiValue: "",
    airQuality: "",
    city: "",
  });
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <WeatherImageContext.Provider value={{ airData, setAirData }}>
      <SearchContext.Provider value={{ newCity, setNewCity }}>
        <cityContext.Provider value={{ location, setLocation }}>
          <SearchpostContext.Provider value={{ search, setSearch }}>
            <aqiDataContext.Provider value={{ aqiData, setAqiData }}>
              <CityContext />
              <Navbar />
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/Showevent" element={<Showevent />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/weather" element={<Weather />} />
                <Route path="/privacy" element={<PrivacypolicyPage />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/forget-password" element={<Forget />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/knowledge-centre" element={<Readings />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/post/:title" element={<PostPage />} />
                <Route path="/events/:id" element={<EventPage />} />
                <Route path="/events" element={<Events />} />
                <Route path="/product" element={<ProductPage />} />
                <Route path="/airquality" element={<AirQuality />} />
                <Route path="/community" element={<Community />} />
                <Route path="/news" element={<News />} />
                <Route path="/update" element={<Updates />} />
                <Route path="/searchpage" element={<Searchpage />} />
                <Route path="/calculators/" element={<Cal />}/>
                <Route path="/calculator/*" element={<Calculator />}>
                  <Route index element={<AQICalculator />} />
                  <Route
                    path="water-consumption"
                    element={<WaterConsumptionCalculator />}
                  />
                  <Route
                    path="tds-blending"
                    element={<TDSBlendingCalculator />}
                  />
                  <Route
                    path="pipeline-size"
                    element={<PipelineSizeCalculator />}
                  />
                  <Route
                    path="air-req"
                    element={<AirRequirementCalculator />}
                  />
                  <Route path="ph-dosing" element={<PHCalculator />} />
                  <Route path="aqi" element={<AQICalculator />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/createblog" element={<CreatePost />} />
                  <Route path="/edit/:postId" element={<EditPost />} />
                </Route>

                <Route element={<AdminRoute />}>
                  <Route
                    path="/editContributor/:postId"
                    element={<EditContributorPost />}
                  />
                  <Route
                    path="/reviewContributor/:postId"
                    element={<ReviewPost />}
                  />
                  <Route
                    path="/contributorpost/:userId"
                    element={<ContributorPost />}
                  />
                </Route>

                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </aqiDataContext.Provider>
          </SearchpostContext.Provider>
        </cityContext.Provider>
      </SearchContext.Provider>
    </WeatherImageContext.Provider>
  );
}
import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import DemandInput from "../Calculations/WaterConsumptionDemands/DemandInput";
import "./WaterConsumptionCalculator.css";

function WaterConsumptionCalculator() {
  const [demands, setDemands] = useState([{ id: 1, totalConsumption: 0 }]);
  const [calculated, setCalculated] = useState(false);
  const [showDownload, setShowDownload] = useState(false); // Initially false
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "https://saffair.in/assets/logo.png";
    img.onload = () => setLogoLoaded(true);

    const mediaQuery = window.matchMedia("(max-width: 600px)");

    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
    };

    // Initial check
    handleMediaChange(mediaQuery);

    // Listen for changes
    mediaQuery.addListener(handleMediaChange);

    // Cleanup listener on unmount
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  const addDemand = () => {
    const newId = demands.length > 0 ? demands[demands.length - 1].id + 1 : 1;
    setDemands([...demands, { id: newId, totalConsumption: 0 }]);
  };

  const removeDemand = (id) => {
    if (demands.length > 1) {
      setDemands(demands.filter((demand) => demand.id !== id));
    }
  };

  const updateDemandConsumption = (id, totalConsumption) => {
    setDemands(
      demands.map((demand) =>
        demand.id === id ? { ...demand, totalConsumption } : demand
      )
    );
  };

  const calculateTotalDemand = () => {
    return demands.reduce(
      (total, demand) => total + demand.totalConsumption,
      0
    );
  };

  const handleCalculate = () => {
    const totalConsumption = calculateTotalDemand();
    if (totalConsumption === 0) {
      setCalculated(false);
      alert("Fill the necessary fields."); // Show the popup
    } else {
      setCalculated(true);
      setShowDownload(true); // Show the download button after calculation
    }
  };

  const calculateTotalSewage = () => {
    return calculateTotalDemand() * 0.9;
  };

  const handleSaveAsImage = () => {
    // Select the buttons and hide them
    const buttons = document.querySelectorAll(
      ".add-demand-button-water-consumption, .calculate-button-water-consumption, .save-image-button, .remove-button-water-consumption"
    );
    buttons.forEach((button) => (button.style.display = "none"));

    // Select the element to capture
    const element = document.querySelector(".tab-content");
    if (element) {
      html2canvas(element, { useCORS: true }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "BOD-based Air Blower Sizing.png";
        link.click();

        // Show the buttons again
        buttons.forEach((button) => (button.style.display = ""));
      });
    }
  };

  return (
    <div className="water-consumption-body">
      <div>
        <h2 className="above-image-text-title">
          BOD-based Air Blower Sizing: AOTR, SOTR, and Air Calculation for
          Efficient Wastewater Treatment
        </h2>
        <h2 className="above-image-text">
          This advanced calculator assists in sizing air blowers for wastewater
          treatment plants based on Biological Oxygen Demand (BOD) using Actual
          Oxygen Transfer Rate (AOTR) and Standard Oxygen Transfer Rate (SOTR).
          Derived from the authoritative Wastewater Engineering book by Metcalf
          and Eddy, this tool ensures precision in designing efficient aeration
          systems, making it indispensable for environmental engineers. Optimize
          your wastewater treatment processes and achieve regulatory compliance
          with this first-of-its-kind tool tailored for professionals.
        </h2>
        <div
          className="title-background-image-container"
          style={{ height: isMobile ? "120px" : "250px" }}
        >
          {" "}
          <img
            src="../assets/calculator-img/air-req-bod.png"
            alt="Water Consumption Image"
            className="title-background-image"
          />
        </div>
        <div className="tab-content">
          <div className="container-without-image">
            <div className="title-container">
              <h2 className="title">BOD-based Air Blower Sizing</h2>
              {logoLoaded && (
                <img
                  src="https://saffair.in/assets/logo.png"
                  alt="Logo"
                  className="title-image"
                />
              )}
            </div>
            {demands.map((demand, index) => (
              <DemandInput
                key={demand.id}
                id={demand.id}
                count={index + 1}
                totalConsumption={demand.totalConsumption}
                onRemove={removeDemand}
                onUpdate={(totalConsumption) =>
                  updateDemandConsumption(demand.id, totalConsumption)
                }
              />
            ))}
            {calculated && (
              <div className="output-section">
                <h5 className="demand-result-title">Water Consumption for,</h5>
                {demands.map((demand) => (
                  <h5 className="demands-result">
                    Demand {demand.id}: {demand.totalConsumption} LPD
                  </h5>
                ))}
                <hr className="divider-water-consumption" />

                <h5 className="final-result-water-consuptions">
                  Total Project Demand: {calculateTotalDemand()} LPD
                </h5>

                <h5 className="final-result-water-consuptions">
                  Total Sewage Produced: {calculateTotalSewage()} LPD
                </h5>
              </div>
            )}{" "}
            <center>
              <button
                className="add-demand-button-water-consumption"
                onClick={addDemand}
              >
                + Add Demands
              </button>
              <button
                className="calculate-button-water-consumption"
                onClick={handleCalculate}
              >
                Calculate
              </button>
              {showDownload && (
                <button
                  onClick={handleSaveAsImage}
                  className="save-image-button"
                >
                  Download
                </button>
              )}
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaterConsumptionCalculator;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./calc.css";

const calculators = [
  {
    label: "AQI",
    path: "/calculator/aqi",
    img: "../assets/calculator-img/aqi-image.png",
  },
  {
    label: "Water Consumption",
    path: "/calculator/water-consumption",
    img: "../assets/calculator-img/water-consumption.png",
  },
  // {
  //   label: "Air Req. for BOD",
  //   path: "/calculator/air-req",
  //   img: "../assets/calculator-img/air-req-bod.png",
  // },
  // {
  //   label: "Pipe Line Size",
  //   path: "/calculator/pipeline-size",
  //   img: "../assets/calculator-img/pipe-line-size.png",
  // },
  {
    label: "TDS Blending",
    path: "/calculator/tds-blending",
    img: "../assets/calculator-img/tds-blending.png",
  },
  // {
  //   label: "pH Dosing",
  //   path: "/calculator/ph-dosing",
  //   img: "../assets/calculator-img/pH-Dosing.png",
  // },
];

function Cal() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="calc-main-page">
      <h2 className="home-title-main-page">Calculators</h2>
      <Container maxWidth="lg" className="home-container">
        <Grid container spacing={3} className="grid-container">
          {calculators.map((calc, index) => (
            <Grid item xs={6} sm={6} md={4} key={index} className="grid-item">
              <Card
                variant="outlined"
                sx={{
                  borderRadius: 5,
                  height: { xs: 250, sm: 300 },
                  width: "100%",
                  aspectRatio: "16 / 9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <CardActionArea
                  onClick={() => navigate(calc.path)}
                  sx={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    position: "relative",
                    zIndex: 3,
                  }}
                >
                  <img src={calc.img} alt={calc.label} />
                  <div className="overlay"></div>
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    align="center"
                    fontWeight="bold"
                    color="#2196ba"
                    className="text"
                  >
                    {calc.label}
                  </Typography>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Cal;
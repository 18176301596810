import React from "react";
import "./vision.css";

export default function Vision() {
  return (
    <div className="vnm ">
      <div className="vision">
        <div className="hh1">Vision</div>
        <div className="vgoal mt-14">
          <p>
            We envision to create Sustainable Ecosystem where clean air is a basic right, driven by community cooperation and innovative solutions.          </p>
        </div>
      </div>
      <div className="vnmphoto">
        <img src="../assets/Work-Being-creative-01.svg" alt="" />
      </div>
      <div className="mission">
        <div className="hh1">Mission</div>
        <div className="mgoal mt-14">
          <p>
            At Saffair, we ensure everyone can breathe clean air by uniting technology with community efforts, making it accessible and reliable.          </p>

        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";

// Standard piping sizes
const pipingSizes = [
  15, 20, 25, 32, 40, 50, 65, 80, 100, 125, 150, 200, 250, 315, 355, 400, 450,
];

// Liquid types and their respective velocities
const liquidTypes = {
  "Clear Water": 2,
  "Gravity Water Flow": 0.6,
  "Effluent / Sludge": 1.2,
  "Enter Manually": null, // For manual entry
};

const PipelineCalculator = () => {
  // States for storing inputs and calculated results
  const [liquidType, setLiquidType] = useState("Enter Manually");
  const [velocity, setVelocity] = useState(0);
  const [flow, setFlow] = useState(0);
  const [calculatedSize, setCalculatedSize] = useState(null);
  const [lineSize, setLineSize] = useState(null);

  // Handle when liquid type is changed
  const handleLiquidTypeChange = (e) => {
    const selectedType = e.target.value;
    setLiquidType(selectedType);
    if (selectedType !== "Enter Manually") {
      setVelocity(liquidTypes[selectedType]);
    } else {
      setVelocity(0); // Allow user to input manually
    }
  };

  // Handle calculation logic
  const calculateLineSize = () => {
    if (velocity <= 0 || flow <= 0) {
      alert("Please enter valid velocity and flow values");
      return;
    }

    // Formula: =SQRT(Flow/(π/4 * Velocity * 3600))*1000
    const calculated =
      Math.sqrt(flow / ((Math.PI / 4) * velocity * 3600)) * 1000;

    // Find the next largest standard piping size
    const roundedSize = pipingSizes.find((size) => size >= calculated);

    // Set the calculated and rounded size in state
    setCalculatedSize(calculated.toFixed(2));
    setLineSize(roundedSize || "N/A");
  };

  return (
    <div>
      <h2>Pipeline Size Calculator</h2>

      {/* Liquid Type Dropdown */}
      <label>
        Type of Liquid:
        <select value={liquidType} onChange={handleLiquidTypeChange}>
          {Object.keys(liquidTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </label>
      <br />

      {/* Velocity Input (only for manual input) */}
      {liquidType === "Enter Manually" && (
        <label>
          Velocity (m/s):
          <input
            type="number"
            min="0"
            step="0.01"
            value={velocity}
            onChange={(e) => setVelocity(Number(e.target.value))}
          />
        </label>
      )}
      {liquidType !== "Enter Manually" && (
        <p>Velocity: {velocity} m/s (based on selected liquid type)</p>
      )}
      <br />

      {/* Flow Input */}
      <label>
        Flow (m3/hr):
        <input
          type="number"
          min="0"
          step="0.01"
          value={flow}
          onChange={(e) => setFlow(Number(e.target.value))}
        />
      </label>
      <br />

      {/* Calculate Button */}
      <button onClick={calculateLineSize}>Calculate</button>
      <br />

      {/* Result Section */}
      {calculatedSize && (
        <div>
          <h3>Results</h3>
          <p>Calculated Line Size: {calculatedSize} mm</p>
          <p>Standard Piping Line Size: {lineSize} mm</p>
        </div>
      )}
    </div>
  );
};

export default PipelineCalculator;

// import React, { useState, useEffect } from "react";
// import html2canvas from "html2canvas";
// import DemandInput from "../Calculations/WaterConsumptionDemands/DemandInput";
// import "./WaterConsumptionCalculator.css";

// function WaterConsumptionCalculator() {
//   const [demands, setDemands] = useState([{ id: 1, totalConsumption: 0 }]);
//   const [calculated, setCalculated] = useState(false);
//   const [showDownload, setShowDownload] = useState(false); // Initially false
//   const [logoLoaded, setLogoLoaded] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const img = new Image();
//     img.src = "https://saffair.in/assets/logo.png";
//     img.onload = () => setLogoLoaded(true);

//     const mediaQuery = window.matchMedia("(max-width: 600px)");

//     const handleMediaChange = (e) => {
//       setIsMobile(e.matches);
//     };

//     // Initial check
//     handleMediaChange(mediaQuery);

//     // Listen for changes
//     mediaQuery.addListener(handleMediaChange);

//     // Cleanup listener on unmount
//     return () => mediaQuery.removeListener(handleMediaChange);
//   }, []);

//   const addDemand = () => {
//     const newId = demands.length > 0 ? demands[demands.length - 1].id + 1 : 1;
//     setDemands([...demands, { id: newId, totalConsumption: 0 }]);
//   };

//   const removeDemand = (id) => {
//     if (demands.length > 1) {
//       setDemands(demands.filter((demand) => demand.id !== id));
//     }
//   };

//   const updateDemandConsumption = (id, totalConsumption) => {
//     setDemands(
//       demands.map((demand) =>
//         demand.id === id ? { ...demand, totalConsumption } : demand
//       )
//     );
//   };

//   const calculateTotalDemand = () => {
//     return demands.reduce(
//       (total, demand) => total + demand.totalConsumption,
//       0
//     );
//   };

//   const handleCalculate = () => {
//     const totalConsumption = calculateTotalDemand();
//     if (totalConsumption === 0) {
//       setCalculated(false);
//       alert("Fill the necessary fields."); // Show the popup
//     } else {
//       setCalculated(true);
//       setShowDownload(true); // Show the download button after calculation
//     }
//   };

//   const calculateTotalSewage = () => {
//     return calculateTotalDemand() * 0.9;
//   };

//   const handleSaveAsImage = () => {
//     // Select the buttons and hide them
//     const buttons = document.querySelectorAll(
//       ".add-demand-button-water-consumption, .calculate-button-water-consumption, .save-image-button, .remove-button-water-consumption"
//     );
//     buttons.forEach((button) => (button.style.display = "none"));

//     // Select the element to capture
//     const element = document.querySelector(".tab-content");
//     if (element) {
//       html2canvas(element, { useCORS: true }).then((canvas) => {
//         const link = document.createElement("a");
//         link.href = canvas.toDataURL("image/png");
//         link.download = "Piping Size Calculator.png";
//         link.click();

//         // Show the buttons again
//         buttons.forEach((button) => (button.style.display = ""));
//       });
//     }
//   };

//   return (
//     <div className="water-consumption-body">
//       <div>
//         <h2 className="above-image-text-title">
//           Piping Size Calculator: Optimize Water & Wastewater Conveyance Based
//           on Velocity Inputs
//         </h2>
//         <h2 className="above-image-text">
//           This user-friendly calculator helps determine the optimal piping size
//           for water or wastewater conveyance by considering selected or manually
//           inputted velocity parameters. Whether for new installations or system
//           upgrades, this tool ensures proper flow rates, reduces pressure drops,
//           and avoids the costs associated with improper sizing. Essential for
//           civil and environmental engineers, this calculator is a game-changer
//           in pipeline design, offering unmatched accuracy and efficiency.
//         </h2>
//         <div
//           className="title-background-image-container"
//           style={{ height: isMobile ? "120px" : "250px" }}
//         >
//           {" "}
//           <img
//             src="../assets/calculator-img/pipe-line-size.png"
//             alt="Water Consumption Image"
//             className="title-background-image"
//           />
//         </div>
//         <div className="tab-content">
//           <div className="container-without-image">
//             <div className="title-container">
//               <h2 className="title">Piping Size Calculator</h2>
//               {logoLoaded && (
//                 <img
//                   src="https://saffair.in/assets/logo.png"
//                   alt="Logo"
//                   className="title-image"
//                 />
//               )}
//             </div>
//             {demands.map((demand, index) => (
//               <DemandInput
//                 key={demand.id}
//                 id={demand.id}
//                 count={index + 1}
//                 totalConsumption={demand.totalConsumption}
//                 onRemove={removeDemand}
//                 onUpdate={(totalConsumption) =>
//                   updateDemandConsumption(demand.id, totalConsumption)
//                 }
//               />
//             ))}
//             {calculated && (
//               <div className="output-section">
//                 <h5 className="demand-result-title">Water Consumption for,</h5>
//                 {demands.map((demand) => (
//                   <h5 className="demands-result">
//                     Demand {demand.id}: {demand.totalConsumption} LPD
//                   </h5>
//                 ))}
//                 <hr className="divider-water-consumption" />

//                 <h5 className="final-result-water-consuptions">
//                   Total Project Demand: {calculateTotalDemand()} LPD
//                 </h5>

//                 <h5 className="final-result-water-consuptions">
//                   Total Sewage Produced: {calculateTotalSewage()} LPD
//                 </h5>
//               </div>
//             )}{" "}
//             <center>
//               <button
//                 className="add-demand-button-water-consumption"
//                 onClick={addDemand}
//               >
//                 + Add Demands
//               </button>
//               <button
//                 className="calculate-button-water-consumption"
//                 onClick={handleCalculate}
//               >
//                 Calculate
//               </button>
//               {showDownload && (
//                 <button
//                   onClick={handleSaveAsImage}
//                   className="save-image-button"
//                 >
//                   Download
//                 </button>
//               )}
//             </center>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default WaterConsumptionCalculator;

import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export default function EventPage() {
  const { id } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [responseCounts, setResponseCounts] = useState({ yes: 0, no: 0, maybe: 0 });
  const [response, setResponse] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventRes = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/events/${id}`);
        if (eventRes.ok) {
          const data = await eventRes.json();
          setEventInfo(data);
        } else {
          console.error("Error fetching event data!");
        }
        if (currentUser) {
          const countsRes = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/events/checkstatus/${id}/${currentUser._id}`);
          setShow(countsRes.data.hasVoted); // Ensure correct data structure
          setResponse(countsRes.data.response);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchEventData();
  }, [id]);

  const handleButtonClick = async (option) => {
    if (!currentUser) {
      setMessage('You must be signed in to submit a response.');
      return;
    }

    try {
      setLoading(true);
      setMessage('');

      await addInterest(option);
      setSelectedResponse(option);
      setMessage('Interest added successfully!');
    } catch (error) {
      console.error('Failed to add interest:', error);
      setMessage('Failed to add interest. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const addInterest = async (response) => {
    try {
      const formData = { response, userId: currentUser._id, eventId: id };
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/events/addInterest/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        throw new Error(`Failed to add interest: ${res.statusText}`);
      }
    } catch (error) {
      console.error('Error adding interest to event:', error);
      throw error;
    }
  };

  const extractDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    return dateObj.toISOString().split('T')[0];
  };

  return (
    <div className="mt-20">
      {eventInfo ? (
        <>
          <img src={eventInfo.eventImage} className="w-full h-64 md:h-screen object-cover" alt="eventImage" />
          <div className="flex flex-col items-start my-4 p-4 lg:p-8 mx-auto max-w-5xl bg-white rounded-lg shadow-md">
            <div className="text-4xl font-bold mb-2 text-teal-600">Event Title: {eventInfo.eventTitle}</div>
            <div className="text-lg text-gray-600">
              Start Date: {extractDate(eventInfo.startDate)} | End Date: {extractDate(eventInfo.endDate)}
            </div>
          </div>
          <div className="post-content mb-6 p-4 lg:p-6 mx-auto max-w-5xl bg-white rounded-lg shadow-md" dangerouslySetInnerHTML={{ __html: eventInfo.eventDescription }} />
          {(eventInfo.link1 || eventInfo.link2) && (
            <div className="flex gap-4 items-start my-4 p-4 lg:p-8 mx-auto max-w-5xl bg-white rounded-lg shadow-md">
              {eventInfo.link1 && (
                <a target="_blank" rel="noopener noreferrer" href={eventInfo.link1} className="bg-blue-100 text-white font-bold py-2 px-4 rounded-full w-[100px] max-w-xs flex flex-row justify-center">
                  <FontAwesomeIcon icon={faLink} className="w-6 h-6 text-blue-500" />
                </a>
              )}
              {eventInfo.link2 && (
                <a target="_blank" rel="noopener noreferrer" href={eventInfo.link2} className="bg-blue-100 text-white font-bold py-2 px-4 rounded-full w-[100px] max-w-xs flex flex-row justify-center">
                  <FontAwesomeIcon icon={faLink} className="w-6 h-6 text-blue-500" />
                </a>
              )}
            </div>
          )}
          <div className="flex flex-col justify-between items-center m-8">
            <h1 className="text-center text-3xl my-7 font-semibold">Are you willing to join?</h1>
            {currentUser ? (
              <>
                {show ? <p className="inline">You have already voted:<span className="text-red-700 mx-2">{response}</span></p> : (<>

                  {
                    selectedResponse ? (
                      <h5 className="flex gap" >
                        Your response <span className="text-red-700 mx-2" > {selectedResponse}</span> recorded.
                      </h5>
                    ) : (
                      <div className="flex justify-center mt-4 space-x-4">
                        <button
                          onClick={() => handleButtonClick("yes")}
                          className={`bg-green-200 hover:bg-green-600 ${selectedResponse === "yes" ? "bg-green-800" : ""} text-white font-bold py-2 px-4 rounded transition duration-300`}
                        >
                          Yes
                        </button>
                        <button
                          onClick={() => handleButtonClick("no")}
                          className={`bg-red-200 hover:bg-red-600 ${selectedResponse === "no" ? "bg-red-800" : ""} text-white font-bold py-2 px-4 rounded transition duration-300`}
                        >
                          No
                        </button>
                        <button
                          onClick={() => handleButtonClick("maybe")}
                          className={`bg-blue-200 hover:bg-blue-600 ${selectedResponse === "maybe" ? "bg-blue-800" : ""} text-white font-bold py-2 px-4 rounded transition duration-300`}
                        >
                          Maybe
                        </button>
                      </div>
                    )}
                </>)}
                {loading && <p>Loading...</p>}
                {message && <p>{message}</p>}
              </>
            ) : (
              <div className="text-sm text-teal-500 my-5 flex gap-1">
                You must be signed in to submit the response. <Link className="text-red-600 hover:underline" to="/login">Sign In</Link>
              </div>
            )
            }
          </div >
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div >
  );
}

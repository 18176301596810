import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDroplet,
  faLocationDot,
  faSun,
  faTemperatureHigh,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import "./weatherupdate.css";
import { useContext } from "react";
import { SearchContext } from "../../../page/Home";
import { cityContext } from "../../../page/Home";
import axios from "axios";
import { Button, Spinner } from "flowbite-react";
import { WeatherImageContext } from "../../../page/Home";
import { TextInput } from "flowbite-react";
import coicon from './co.png'
import so2icon from './so2.png'
import pm2icon from './pm 2.5.png'
import pm10icon from './pm 10.png'
import Loadder from "../../../lottie/Loadder";
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
const libraries = ['places'];

export default function WeatherUpdate() {
  const [errormessage, setErormessage] = useState(false);
  const { airData, setAirData } = useContext(WeatherImageContext);
  const { newCity, setNewCity } = useContext(SearchContext);
  const { location, setLocation } = useContext(cityContext);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(location);
  const [weather, setWeather] = useState({
    loading: false,
    data: {},
    error: false,
  });
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState(null);

  const fetchLocationInfo = async (cityName) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(cityName)}&key=YOUR_API_KEY`
      );

      const data = await response.json();

      if (data.status === "OK") {
        const result = data.results[0];
        const pincodeComponent = result.address_components.find(
          (component) => component.types.includes("postal_code")
        );
        const stateComponent = result.address_components.find(
          (component) => component.types.includes("administrative_area_level_1")
        );

        const pincode = pincodeComponent ? pincodeComponent.short_name : "N/A";
        const state = stateComponent ? stateComponent.long_name : "N/A";

        setPincode(pincode);
        setState(state);
      } else {
        console.error("Geocoding API error:", data.status);
        setError("Error fetching location info");
      }
    } catch (error) {
      console.error("Error fetching location info:", error);
      setError("Error fetching location info");
    }
  };

  useEffect(() => {
    fetchLocationInfo(location);
  }, [location]); // Fetch location info when location changes

  const [aqi, setAqi] = useState("");
  const [pm2_5, setPm2_5] = useState("20");
  const [pm10, setPm10] = useState("20");
  const [co, setCo] = useState("20");
  const [so2, setSo2] = useState("20");
  const toDateFunction = () => {
    const currentDate = new Date();
    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
    const WeekDays = [
      "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ];

    // Get the date components
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const weekday = WeekDays[currentDate.getDay()];

    // Get the time components
    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Combine date and time into a single string
    const date = `${day} ${month}, ${weekday} ${hours}:${formattedMinutes} ${ampm}`;

    return date // Outputs the formatted date and time

  };

  useEffect(() => {
    setLoading(true);
    setWeather({ ...weather });
    const url = "https://api.openweathermap.org/data/2.5/weather";
    axios
      .get(url, {
        params: {
          q: location || "Ahmedabad",
          units: "metric",
          appid: process.env.REACT_APP_WEATHER_KEY,
        },
      })
      .then((res) => {
        setWeather({ data: res.data, loading: false, error: false });
        setLoading(false);
      })
      .catch((error) => {
        setWeather({ ...weather, data: {}, error: true });
        setInput("");
      });

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${location}&appid=5162096789da4a96c75f4792c5e4628e`
        );
        const data = await response.json();
        const { coord } = data; // Destructure coord from data object
        const apiKey = "5162096789da4a96c75f4792c5e4628e";
        const aqiRes = await fetch(
          `https://api.openweathermap.org/data/2.5/air_pollution?lat=${coord.lat}&lon=${coord.lon}&appid=${process.env.REACT_APP_WEATHER_KEY}`
        );
        const aqidata = await aqiRes.json();
        console.log(aqidata);
        const aqi = aqidata.list[0].main.aqi;
        const pm2_5value = aqidata.list[0].components.pm2_5;
        const pm10value = aqidata.list[0].components.pm10;
        const covalue = aqidata.list[0].components.co;
        const so2value = aqidata.list[0].components.so2;
        setPm2_5(pm2_5value);
        setPm10(pm10value);
        setCo(covalue);
        setSo2(so2value);
        setAqi(aqi);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [location]);

  const search = async () => {
    setLoading(true);
    try {
      setNewCity(input);

      setWeather({ ...weather });

      const url = "https://api.openweathermap.org/data/2.5/weather";
      const api_key = "5162096789da4a96c75f4792c5e4628e";

      // Fetch weather data using Axios
      const axiosResponse = await axios.get(url, {
        params: {
          q: input,
          units: "metric",
          appid: api_key,
        },
      });
      // console.log(axiosResponse.code);
      // if (axiosResponse.code === 404) {
      //   alert("city  not found");
      //   setLoading(false);
      // }

      // Update weather state with fetched data
      setWeather({ data: axiosResponse.data, error: false });
      const { coord } = axiosResponse.data;
      if (coord) {
        // Destructure coord from data object
        const apiKey = "5162096789da4a96c75f4792c5e4628e";
        const aqiRes = await fetch(
          `https://api.openweathermap.org/data/2.5/air_pollution?lat=${coord.lat}&lon=${coord.lon}&appid=${apiKey}`
        );
        const aqidata = await aqiRes.json();
        const aqi = aqidata.list[0].main.aqi;
        const pm2_5value = aqidata.list[0].components.pm2_5;
        const pm10value = aqidata.list[0].components.pm10;
        const covalue = aqidata.list[0].components.co;
        const so2value = aqidata.list[0].components.so2;
        setPm2_5(pm2_5value);
        setPm10(pm10value);
        setCo(covalue);
        setSo2(so2value);
        setAqi(aqi);
        setLoading(false);
        setErormessage(false)
      } else {
        // If coordinates are not available, display an error message
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching weather data:", error);
      setWeather({ ...weather, data: {}, error: true });
      setLoading(false);
      setErormessage(true);
      setInput("");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const getBackgroundImage = () => {
    const imageMap = {
      1: "./assets/aqiImg/good.jpg",
      2: "./assets/aqiImg/Moderate.png",
      3: "./assets/aqiImg/Unhealthy.jpg",
      4: "./assets/aqiImg/Very unhealthy.jpg",
      5: "./assets/aqiImg/hazardous.jpg",
    };
    return imageMap[aqi] || "./assets/aqiImg/good.jpg";
  };

  const getHealthAdviceImage = () => {
    const imageMap = {
      1: "./assets/G.svg",
      2: "./assets/M.svg",
      3: "./assets/U.svg",
      4: "./assets/V.svg",
      5: "./assets/H.svg",
    };
    return imageMap[aqi] || "./assets/aqiImg/G.svg";
  };

  const getHealthAdviceImage2 = () => {
    const imageMap = {
      2: "./assets/aqiImg/GM.svg",
      1: "./assets/aqiImg/MM.svg",
      3: "./assets/aqiImg/UM.svg",
      4: "./assets/aqiImg/VM.svg",
      5: "./assets/aqiImg/HM.svg",
    };
    return imageMap[aqi] || "./assets/aqiImg/GM.svg";
  };

  var aqiValue;
  var air_quailty;
  if (aqi === 1) {
    aqiValue = "0 - 100";
    air_quailty = "Good";
  }
  if (aqi === 2) {
    aqiValue = "100 - 200";
    air_quailty = "Moderate";
  }
  if (aqi === 3) {
    aqiValue = "200 - 300";
    air_quailty = "Unhealthy";
  }
  if (aqi === 4) {
    aqiValue = "400 - 500";
    air_quailty = "Unhealthy";
  }
  if (aqi === 5) {
    aqiValue = "500 - 600";
    air_quailty = "Hazardous";
  }

  useEffect(() => {
    let newAqiValue;
    let newAirQuality;
    let imgValue;

    if (aqi === 1) {
      newAqiValue = "0 - 100";
      newAirQuality = "Good";
      imgValue = "./assets/aqiImg/good.jpg";
    } else if (aqi === 2) {
      newAqiValue = "100 - 200";
      newAirQuality = "Moderate";
      imgValue = "./assets/aqiImg/Moderate.png";
    } else if (aqi === 3) {
      newAqiValue = "200 - 300";
      newAirQuality = "Unhealthy";
      imgValue = "./assets/aqiImg/Unhealthy.jpg";
    } else if (aqi === 4) {
      newAqiValue = "400 - 500";
      newAirQuality = "Unhealthy";
      imgValue = "./assets/aqiImg/Very unhealthy.jpg";
    } else if (aqi === 5) {
      newAqiValue = "500 - 600";
      newAirQuality = "Hazardous";
      imgValue = "./assets/aqiImg/hazardous.jpg";
    }

    // Set the new values using setAirData
    setAirData((prevAirData) => ({
      ...prevAirData,
      imageUrl: imgValue,
      aqiValue: newAqiValue,
      airQuality: newAirQuality,
      city: weather.data.name,
    }));
  }, [aqi]); // Run this effect whenever the 'aqi' state changes




  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEPLACES_API_KEY,
    libraries,
  });

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setInput(place.name);
      setLocation(place.name);
      search();
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  // ... [keep all existing useEffect hooks and functions]

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  return (
    <div className="weather-Container">
      <div className="i-container h-[750px] ">
        <img
          src={getBackgroundImage()}
          alt="cloudy"
          className="background-image w-full h-full object-cover"
        />


        <div className="absolute inset-0 bg-black bg-opacity-25 z-10"></div>

        <div className="relative z-20 p-4">
          {loading ? (
            <div className="spinnerr">
              <Loadder />
            </div>
          ) : (
            <div>
              <div className="content">
                <div className="whole ">
                  <div className="fhalf">
                    <div className="mx-auto">
                      {weather.error && (
                        <>
                          <br />
                          <br />
                          <span className="error-message">
                            <span style={{ fontSize: "20px" }}></span>
                          </span>
                        </>
                      )}
                      {!weather.error && weather.data && weather.data.main ? (
                        <div className="bold">
                          <div className=" thevery   text-left" id="very">
                            {aqi === 4 ? "Very " : ""}
                          </div>
                          <p className="headtext  ">{air_quailty}</p>
                          <div className="infopluslogo flex items-center">
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              className="text-4xl lg:mr-2"
                            />
                            <div className="infodata">
                              <p className="thecity">{weather.data.name}</p>
                              <p className="thecity">{pincode}</p>
                              <p className="thecity">{state}</p>
                              <p className="timedate">{toDateFunction()}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="bold">
                          <p className="headtext mt-0">Good</p>
                          <div className="infopluslogo flex items-center">
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              className="text-3xl lg:mr-2 lc"
                            />
                            <div className="infodata">
                              <p className="thecity">Surat</p>
                              <p className="timedate">27 April</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="shalf">
                    <div className="scontainer">
                      {isLoaded ? (
                        <div className="flex items-center space-x-2">
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            options={{
                              types: ['(cities)'],
                              componentRestrictions: { country: 'in' }
                            }}
                          >
                            <input
                              type="text"
                              className="w-64 h-10 px-3 py-2 text-sm bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Search for a city in India"
                              name="query"
                              value={input}
                              onChange={handleInputChange}
                              onKeyPress={handleKeyPress}
                            />
                          </Autocomplete>
                          <button
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={search}
                          >
                            Search
                          </button>
                        </div>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                    {errormessage ? <p className="not">City not found</p> : ""}
                    <div className="impdiv mt-4 pl-3">
                      <div className="twoparts">
                        <div className="theaqi flex flex-col justify-center">
                          <div className="charttag ">AQI : {aqiValue}</div>
                          {/* <div className="charts">
                           <FontAwesomeIcon
                            icon={faChartSimple}
                            className="text-4xl text-white lg"
                          /> 
                          <p className="chartvalue">{aqiValue}</p> 
                        </div> */}
                          <div className="grido sgrid">
                            <div className="n4 sgrp">
                              <img src={pm2icon} alt="co"
                                className="h-5 w-5 sm:h-8 sm:w-8 md:h-10 md:w-10object-contain text-2xl lg " />


                              <div className="n1data txt">
                                <span>
                                  <p className="inline n1name whitespace-nowrap">
                                    PM 2.5
                                  </p>
                                </span>

                                <p className="n3value whitespace-nowrap">
                                  {pm2_5} <span id="units">µg/m³</span>
                                </p>
                              </div>
                            </div>
                            <div className="n4 sgrp">
                              <img src={pm10icon} alt="co"
                                className="h-5 w-5 sm:h-8 sm:w-8 md:h-8 md:w-8 lg:h-8 lg:w-8 object-contain text-2xl lg " />


                              <div className="n1data txt">
                                <span>
                                  <p className="n1name whitespace-nowrap">
                                    PM 10
                                  </p>
                                </span>

                                <p className="n3value whitespace-nowrap ">
                                  {pm10} <span id="units">µg/m³</span>
                                </p>
                              </div>
                            </div>

                            <div className="n4 sgrp">
                              <img src={coicon} alt="co"
                                className="h-5 w-5 sm:h-8 sm:w-8 md:h-10 md:w-10 object-contain text-2xl lg " />

                              <div className="n1data txt">

                                <p className="n1name">
                                  CO
                                </p>
                                <p className="n3value whitespace-nowrap">
                                  {co} <span id="units">µg/m³</span>
                                </p>
                              </div>
                            </div>

                            <div className="n4 sgrp">
                              <img src={so2icon} alt="co"
                                className="h-5 w-5 sm:h-8 sm:w-8 md:h-8 md:w-8 lg:h-8 lg:w-8 object-contain text-2xl lg " />

                              <div className="n1data txt">

                                <p className="n1name">
                                  SO<sub>2</sub>
                                </p>
                                <p className="n3value whitespace-nowrap ">
                                  {so2} <span id="units">µg/m³</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="hori-line"></div>
                        </div>

                        <div className="wea">
                          <div className="tago">
                            <p className="thetag"></p>
                          </div>
                          {weather && weather.data && weather.data.main ? (
                            <div className="grido wthr">
                              <div className="n1">
                                <FontAwesomeIcon
                                  icon={faTemperatureHigh}
                                  className="text-2xl lg"
                                />
                                <div className="n1data">
                                  <p className="n1name">Temperature</p>
                                  <p className="n1value">
                                    {" "}
                                    {weather.data.main.temp} °C{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="n2">
                                <FontAwesomeIcon
                                  icon={faWind}
                                  className="text-2xl lg"
                                />
                                <div className="n2data">
                                  <p className="n2name">Wind</p>
                                  <p className="n1value">
                                    {weather.data.wind.speed} m/s
                                  </p>
                                </div>
                              </div>
                              <div className="n3 special">
                                <FontAwesomeIcon
                                  icon={faDroplet}
                                  className="text-2xl lg"
                                />
                                <div className="n3data runique plus ">
                                  <p className="n3name">Humidity</p>
                                  <p className="n3value ">
                                    {weather.data.main.humidity}
                                  </p>
                                </div>
                              </div>
                              <div className="n4 special">
                                <FontAwesomeIcon
                                  icon={faSun}
                                  className="text-2xl lg"
                                />
                                <div className="n4data ">
                                  <p className="n4name">UV Index</p>
                                  <p className="n3value">3</p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="grido wthr">
                              <div className="n1">
                                <FontAwesomeIcon
                                  icon={faTemperatureHigh}
                                  className="text-4xl lg"
                                />
                                <div className="n1data">
                                  <p className="n1name">Temperature</p>
                                  <p className="n1value">21°C </p>
                                </div>
                              </div>
                              <div className="n2">
                                <FontAwesomeIcon
                                  icon={faWind}
                                  className="text-4xl lg"
                                />
                                <div className="n2data">
                                  <p className="n2name">Wind</p>
                                  <p className="n2value">2.06 m/s</p>
                                </div>
                              </div>
                              <div className="n3 special">
                                <FontAwesomeIcon
                                  icon={faDroplet}
                                  className="text-4xl lg"
                                />
                                <div className="n3data runique plus ">
                                  <p className="n3name">Humidity</p>
                                  <p className="n3value ">36</p>
                                </div>
                              </div>
                              <div className="n4 special">
                                <FontAwesomeIcon
                                  icon={faSun}
                                  className="text-4xl lg"
                                />
                                <div className="n4data ">
                                  <p className="n4name">UV Index</p>
                                  <p className="n3value">3</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          )}
        </div>

      </div>
      <div className="HA">
        <img
          className="HA2"
          src={getHealthAdviceImage()}
          alt="img"
          height="800px"
        />
        <img className="HA1" src={getHealthAdviceImage2()} alt="img" />
      </div>
    </div>
  );
}

import axios from "axios";
import { Button, FileInput } from "flowbite-react";
import { useCallback, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CreateVoucherForm() {
  const [voucherName, setVoucherName] = useState("");
  const [voucherCost, setVoucherCost] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [code, setCode] = useState("");
  const [url, seturl] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shift = 3;

  const [info, setinfo] = useState("");
  function encryptVoucherCode(code) {
    return code
      .split('')
      .map(char => {
        if (char.match(/[a-z]/i)) {
          const code = char.charCodeAt(0);
          if ((code >= 65) && (code <= 90)) {
            return String.fromCharCode(((code - 65 + shift) % 26) + 65);
          } else if ((code >= 97) && (code <= 122)) {
            return String.fromCharCode(((code - 97 + shift) % 26) + 97);
          }
        }
        return char;
      })
      .join('');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Add your form submission logic here
    const voucherData = {
      name: voucherName,
      cost: voucherCost,
      expiryDate: expiryDate,
      info: info,
      img: fileUrl
    };

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/vouchers/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(voucherData),
      });

      const data = await res.json();
      if (!res.ok) {
        console.error(data.message);
      } else {
        console.log("Voucher created successfully:", data);
        // Reset form fields
        setVoucherName("");
        setVoucherCost("");
        setExpiryDate("");
        setCode("");
        setFileUrl("");
        // Optionally, redirect to another page
        navigate("/dashboard?tab=voucherlist");
      }
    } catch (error) {
      console.error("Error creating voucher:", error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const allowedFileTypes = [
    'image/png',
    'image/jpeg'
  ];

  const handleFileSelect = useCallback((event) => {
    const file = event.target.files[0];
    if (file && allowedFileTypes.includes(file.type)) {
      setSelectedFile(file);
    } else {
      alert('Please select a valid file type (PNG or JPG)');
      event.target.value = null;
    }
  }, []);

  const uploadToS3 = useCallback(async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      }
    };
    await axios.put(signedRequest, file, options);
  }, []);

  const handleUpload = useCallback(async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }
    setUploading(true);
    setUploadProgress(0);
    try {
      // Get the presigned URL
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/api/img/get-presigned-url`, {
        params: {
          fileName: selectedFile.name,
          fileType: selectedFile.type
        }
      });
      const { signedRequest, url } = response.data;
      // Upload to S3
      await uploadToS3(selectedFile, signedRequest);
      // Set the file URL in state
      setFileUrl(url);

      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file.');
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  }, [selectedFile, uploadToS3]);


  return (

    <div className="w-full p-4">
      <h2 className="  text-xl font-bold mb-4">Create Voucher</h2>
      <form onSubmit={handleSubmit}>
        <div className=" w-[300px] mb-4">
          <label htmlFor="voucherName" className="block text-sm font-medium text-gray-700">
            Voucher Name
          </label>
          <input
            type="text"
            id="voucherName"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            value={voucherName}
            onChange={(e) => setVoucherName(e.target.value)}
            required
          />
        </div>
        <div className=" w-[300px] mb-4">
          <label htmlFor="voucherCost" className="block text-sm font-medium text-gray-700">
            Voucher Coins cost
          </label>
          <input
            type="number"
            id="voucherCost"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            value={voucherCost}
            onChange={(e) => setVoucherCost(e.target.value)}
            required
          />
        </div>
        <div className="w-[300px] mb-4">
          <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
            Expiry Date
          </label>
          <input
            type="date"
            id="expiryDate"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            required
          />
        </div>
        <div className="w-[300px] mb-4">
          <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">
            voucher info
          </label>
          <input
            type="info"
            id="info"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            value={info}
            onChange={(e) => setinfo(e.target.value)}
            required
          />
        </div>
        <div>
          <div className="w-[300px] mb-4 flex gap-4 items-center justify-between border-4 border-teal-500 border-dotted p-3">
            <FileInput
              type="file"
              accept=".png,.jpg,.jpeg"
              onChange={handleFileSelect}
            />
            <Button
              type="button"
              gradientDuoTone="cyanToBlue"
              size="sm"
              outline
              onClick={handleUpload}
              disabled={uploading || !selectedFile}
            >
              {(uploading) ? (
                <div className="w-16 h-16">
                  <CircularProgressbar
                    value={uploadProgress}
                    text={`${uploadProgress}%`}
                  />
                </div>
              ) : (
                "Upload image"
              )}
            </Button>
          </div>
          {/* {(documentUploadError || imageUploadError) && <Alert color="failure">{(documentUploadError || imageUploadError)}</Alert>} */}
          {/* {(formData.pdf || formData.image) && (
              <img
                src={formData.pdf || formData.image}
                alt="upload"
                className="w-full h-72 object-cover"
              />
            )} */}
          {
            (fileUrl) ? <img
              src={fileUrl}
              alt={fileUrl}
              className="w-[300px] mb-4 h-72 object-cover"
            /> : <>
              {/* {documentDownloadURL && (
                <PDFPreview pdfPath={documentDownloadURL} />
              )} */}

            </>

          }
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-md"
        >
          Create Voucher
        </button>
      </form>
    </div>
  );
}

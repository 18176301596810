import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Reward.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Table, Pagination } from 'flowbite-react';
import share from "./share.png";

const VoucherList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vouchers, setVouchers] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [coin, setCoin] = useState(0);
  const [expandedVouchers, setExpandedVouchers] = useState({});
  const [redeemedVouchers, setRedeemedVouchers] = useState({
    data: [],
    totalItems: 0,
    currentPage: 1,
    totalPages: 1
  });

  useEffect(() => {
    fetchVouchers();
    fetchData();
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.isAdmin) {
      fetchRedeemedVouchers(1);
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/user/${currentUser._id}`);
      if (res.ok) {
        const data = await res.json();
        setCoin(data.totalCoins);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRedeemedVouchers = async (page) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/vouchers/redeemed-vouchers?page=${page}&limit=50`);
      const data = await res.json();
      if (!res.ok) {
        console.error(data.message);
      } else {
        setRedeemedVouchers(data);
      }
    } catch (error) {
      console.error('Error fetching redeemed vouchers:', error);
    }
  };

  const fetchVouchers = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/vouchers/list`);
      const data = await res.json();
      if (!res.ok) {
        console.error(data.message);
      } else {
        setVouchers(data);
      }
    } catch (error) {
      console.error('Error fetching vouchers:', error);
    }
  };

  const handleRedeem = async (voucherId, voucherCost) => {
    if (!currentUser.isVerify) {
      alert("You are not a verified user. Please verify your account to redeem the voucher.");
      navigate("/dashboard?tab=verify");
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/vouchers/redeem/${voucherId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: currentUser._id, voucherCost }),
      });
      const data = await res.json();
      if (!res.ok) {
        alert(data.message);
      } else {
        alert('Voucher redeemed successfully! You will get the voucher within 2-3 days on your mail ID or Phone Number');
        dispatch({
          type: 'UPDATE_USER_COINS',
          payload: data.newTotalCoins
        });
      }
    } catch (error) {
      console.error('Error redeeming voucher:', error);
      alert('An error occurred while redeeming the voucher.');
    }
  };

  const handledelete = async (id) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/vouchers/delete/${id}`, {
        method: 'DELETE',
      });
      const data = await res.json();
      if (!res.ok) {
        console.error(data.message);
      } else {
        alert('Voucher deleted successfully!');
        fetchVouchers();
      }
    } catch (error) {
      console.error('Error deleting voucher:', error);
      alert('An error occurred while deleting the voucher.');
    }
  };

  const maxLength = 100;

  const handleToggle = (voucherId) => {
    setExpandedVouchers(prev => ({
      ...prev,
      [voucherId]: !prev[voucherId]
    }));
  };

  const renderVoucherDescription = (voucher) => {
    const isExpanded = expandedVouchers[voucher._id];
    return (
      <>
        <p>
          {isExpanded ? voucher.info : `${voucher.info.substring(0, maxLength)}...`}
        </p>
        <button
          onClick={() => handleToggle(voucher._id)}
          className="text-[#2196ba] hover:underline"
        >
          {isExpanded ? 'Read less' : 'Read more'}
        </button>
      </>
    );
  };

  const isVoucherExpired = (expiryDate) => {
    return new Date(expiryDate) < new Date();
  };

  const filteredVouchers = currentUser.isAdmin
    ? vouchers
    : vouchers.filter(voucher => !isVoucherExpired(voucher.expiryDate));

  return (
    <div className="reward-container">
      <div className="title">Vouchers</div>
      <div className="reward-cards">
        {filteredVouchers.map((voucher) => (
          <div key={voucher._id} className="reward-card flex flex-col justify-between">
            <div className="voucher-info">
              <img src={voucher.img} alt="img" />
              <h2>{voucher.name}</h2>
              <p className='font-bold text-lg'>Coins: {voucher.cost}</p>
              <p className={`font-semibold ${isVoucherExpired(voucher.expiryDate) ? 'text-red-500' : 'text-green-500'}`}>
                Expiry Date: {new Date(voucher.expiryDate).toLocaleDateString()}
                {isVoucherExpired(voucher.expiryDate) && currentUser.isAdmin && ' (Expired)'}
              </p>
              {renderVoucherDescription(voucher)}
            </div>
            <div className='flex flex-row justify-between items-center '>
              <div className='flex flex-row gap-1 items-center'>
                {!isVoucherExpired(voucher.expiryDate) && (
                  <button
                    onClick={() => handleRedeem(voucher._id, voucher.cost)}
                    className="redeem-button font-bold text-[#2196ba] bg-white border-6 border-[#2196ba] rounded-lg px-6 py-2 text-xl"
                    style={{ border: '4px solid #2196ba' }}
                    disabled={currentUser.totalCoins < voucher.cost}
                  >
                    {currentUser.totalCoins < voucher.cost ? 'Insufficient Coins' : 'Redeem'}
                  </button>
                )}
                <a target="_blank" rel="noopener noreferrer" href={voucher.url} className="self-center text-white font-bold rounded-full w-8 h-8 flex flex-row justify-center">
                  <img src={share} alt="share" />
                </a>
              </div>
              {currentUser.isAdmin && (
                <button
                  onClick={() => handledelete(voucher._id)}
                  className="redeem-button bg-red-600"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {currentUser.isAdmin && (
        <>
          <div className='title mt-4'>
            Redeemed Voucher History
          </div>
          <div className="mt-2 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
            <Table hoverable>
              <Table.Head>
                <Table.HeadCell>User Email</Table.HeadCell>
                <Table.HeadCell>Phone Number</Table.HeadCell>
                <Table.HeadCell>Date</Table.HeadCell>
                <Table.HeadCell>Voucher Name</Table.HeadCell>
                <Table.HeadCell>Voucher Cost</Table.HeadCell>
                <Table.HeadCell>User Name</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {redeemedVouchers.data.map((voucher) => (
                  <Table.Row key={voucher._id} className="bg-white">
                    <Table.Cell>
                      <span>{voucher.userEmail}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <span>{voucher.userPhone}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <span>
                        {new Date(voucher.redeemedDate).toLocaleString('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                        })}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <span>{voucher.voucherName}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <span>{voucher.voucherCost}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <span>{`${voucher.firstName} ${voucher.lastName}`}</span>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className="flex overflow-x-auto sm:justify-center">
            <Pagination
              currentPage={redeemedVouchers.currentPage}
              totalPages={redeemedVouchers.totalPages}
              onPageChange={fetchRedeemedVouchers}
              showIcons={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VoucherList;
import { Table } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCopy } from 'react-icons/fa';

const Newsletter = () => {
    const [data, setData] = useState([]);
    const { currentUser } = useSelector((state) => state.user);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND_API}/api/listnews`
                );
                const fetchedData = await res.json();
                if (res.ok) {
                    setData(fetchedData);
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchPosts();
    }, [currentUser._id]);

    const copyToClipboard = (email) => {
        navigator.clipboard.writeText(email).then(() => {
            alert(`Copied: ${email}`);
        });
    };

    const copyAllEmails = () => {
        const allEmails = data.map(post => post.email).join(', ');
        navigator.clipboard.writeText(allEmails).then(() => {
            alert(`Copied all emails: ${allEmails}`);
        });
    };

    return (
        <div>
            <h2>Newsletter</h2>
            {currentUser.isAdmin && data.length > 0 ? (
                <>
                    <button
                        onClick={copyAllEmails}
                        className="mb-4 p-2 bg-[#2196BA] text-white rounded"
                    >
                        Copy All Emails
                    </button>
                    <Table hoverable className="shadow-md">
                        <Table.Head>
                            <Table.HeadCell>Email</Table.HeadCell>
                            <Table.HeadCell>Copy</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {data.map((post) => (
                                <Table.Row
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    key={post._id}
                                >
                                    <Table.Cell>{post.email}</Table.Cell>
                                    <Table.Cell>
                                        <FaCopy
                                            className="cursor-pointer"
                                            onClick={() => copyToClipboard(post.email)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            ) : (
                <p>No posts match your search criteria.</p>
            )}
        </div>
    );
};

export default Newsletter;

import React, { useState, useEffect } from "react";
import "../../components/WaterConsumptionCalculator.css";

const projectTypes = {
  Industry: [
    "Industry (with Canteen & Bathrooms)",
    "Industry (with Canteen but without bathrooms)",
    "Labour Colony",
  ],
  "Residential Commercial": [
    "Residential",
    "Offices (with canteen)",
    "Offices (w/o canteen)",
    "Shopping Mall Staff",
    "Visitors",
  ],
  Institutional: ["School/College", "Hostels", "Staff Quarters"],
  "Hotels Restaurants": [
    "Hotels <3star (excluding Laundry, Kitchen, Staff & Water bodies)",
    "Hotels 4 star+ (excluding Laundry, Kitchen, Staff & Water bodies)",
    "Restaurant",
    "Food court",
  ],
  "Public Places": [
    "Stadiums",
    "Theaters, Multiplex, Concert Halls",
    "Airport",
    "Railway Station (Junction) w/o bathing",
    "Railway Station (Intermediate) w/o bathing",
    "Railway Station (Junction) with bathing",
    "Railway Station (Intermediate) with bathing",
    "Interstate bus terminals",
    "Intrastate Bus Terminals/Metro Stations",
  ],
  Hospitals: [
    "Hospitals <100 beds (Excluding Laundry & Kitchen)",
    "Hospitals >100 beds (Excluding Laundry & Kitchen)",
    "OPDs",
    "Nurses Home / Medical Quarters",
  ],
};

const consumptionRates = {
  "Industry (with Canteen & Bathrooms)": 45,
  "Industry (with Canteen but without bathrooms)": 30,
  "Labour Colony": 135,
  Residential: 135,
  "Offices (with canteen)": 45,
  "Offices (w/o canteen)": 20,
  "Shopping Mall Staff": 45,
  Visitors: 15,
  "School/College": 45,
  Hostels: 135,
  "Staff Quarters": 135,
  "Hotels <3star (excluding Laundry, Kitchen, Staff & Water bodies)": 180,
  "Hotels 4 star+ (excluding Laundry, Kitchen, Staff & Water bodies)": 320,
  Restaurant: 70,
  "Food court": 35,
  Stadiums: 10,
  "Theaters, Multiplex, Concert Halls": 15,
  Airport: 70,
  "Railway Station (Junction) w/o bathing": 45,
  "Railway Station (Intermediate) w/o bathing": 25,
  "Railway Station (Junction) with bathing": 70,
  "Railway Station (Intermediate) with bathing": 45,
  "Interstate bus terminals": 45,
  "Intrastate Bus Terminals/Metro Stations": 15,
  "Hospitals <100 beds (Excluding Laundry & Kitchen)": 340,
  "Hospitals >100 beds (Excluding Laundry & Kitchen)": 450,
  OPDs: 15,
  "Nurses Home / Medical Quarters": 135,
};

function DemandInput({ id, totalConsumption, count, onRemove, onUpdate }) {
  const [projectType, setProjectType] = useState("");
  const [waterDemandFrom, setWaterDemandFrom] = useState("");
  const [noOfPersons, setNoOfPersons] = useState(0);

  useEffect(() => {
    if (!projectType || !waterDemandFrom) {
      onUpdate(0);
    }
  }, [projectType, waterDemandFrom]);

  const handleProjectTypeChange = (e) => {
    setProjectType(e.target.value);
    setWaterDemandFrom("");
  };

  const handleWaterDemandChange = (e) => {
    setWaterDemandFrom(e.target.value);
    if (e.target.value && noOfPersons > 0) {
      onUpdate(consumptionRates[e.target.value] * noOfPersons);
    } else {
      onUpdate(0);
    }
  };

  const handlePersonsChange = (e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setNoOfPersons(value);
    if (waterDemandFrom) {
      onUpdate(consumptionRates[waterDemandFrom] * value);
    }
  };

  return (
    <div className="demand-input">
      <div className="demand-title">Demand {count}</div>
      <label>Project Type:</label>
      <select value={projectType} onChange={handleProjectTypeChange}>
        <option value="">Select Project Type</option>
        {Object.keys(projectTypes).map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>

      <label>Water Demand from:</label>
      <select
        value={waterDemandFrom}
        onChange={handleWaterDemandChange}
        disabled={!projectType}
      >
        <option value="">Select Water Demand</option>
        {projectType &&
          projectTypes[projectType].map((demand) => (
            <option key={demand} value={demand}>
              {demand}
            </option>
          ))}
      </select>

      <label>No. of Persons:</label>
      <input
        type="number"
        value={noOfPersons}
        onChange={handlePersonsChange}
        min="1"
      />

      {totalConsumption > 0 && (
        <h5 className="h2-water-consumption">
          Total Water Consumption: {totalConsumption} LPD
        </h5>
      )}

      <button
        className="remove-button-water-consumption"
        onClick={() => onRemove(id)}
      >
        Remove Demand
      </button>
    </div>
  );
}

export default DemandInput;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal, Table, Button } from "flowbite-react";

export default function DashEventEntrys() {
  const { currentUser } = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/events/Events`);
        if (!response.ok) {
          throw new Error("Error while fetching events!");
        }
        const data = await response.json();
        setEvents(data);
        console.log(data);
      } catch (error) {
        console.error(error);
        // Handle error
      }
    };
    fetchData();
  }, []);

  const deleteEvent = async (postIdToDelete) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/api/events/deleteEvent/${postIdToDelete}/${currentUser._id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Error while deleting event!");
      }
      await response.json();
      setEvents(events.filter((event) => event._id !== postIdToDelete));
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  return (
    <div>
      <h2 className="mb-2">Event Entries</h2>
      <div className="table-auto p-4 mx-auto overflow-x-scroll md:overflow-x-auto scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
        <Table hoverable className="shadow-md">
          <Table.Head>
            <Table.HeadCell>Date updated</Table.HeadCell>
            <Table.HeadCell>Event image</Table.HeadCell>
            <Table.HeadCell>Event title</Table.HeadCell>
            <Table.HeadCell>Interest</Table.HeadCell>
            <Table.HeadCell>Delete</Table.HeadCell>
            <Table.HeadCell>
              <span>Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {events.map((event) => (
              <Table.Row key={event._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell>
                  {new Date(event.updatedAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  })}
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/events/${event._id}`}>
                    <img
                      src={event.eventImage}
                      alt={event.title}
                      className="w-20 h-10 object-cover bg-gray-500"
                    />
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link
                    className="font-medium text-gray-900 dark:text-white"
                    to={`/events/${event._id}`}
                  >
                    {event.eventTitle}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {event.counts ? (
                    <div>
                      <p>Yes: {event.counts.yes}</p>
                      <p>No: {event.counts.no}</p>
                      <p>Maybe: {event.counts.maybe}</p>
                    </div>
                  ) : (
                    <p>No data</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <span
                    onClick={() => deleteEvent(event._id)}
                    className="font-medium text-red-500 hover:underline cursor-pointer"
                  >
                    Delete
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <Link
                    className="text-teal-500 hover:underline"
                    to={`/events/${event._id}`}
                  >
                    <span>View</span>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}
import React, { useState } from "react";
import "./joinletter.css";

export default function Joinletter() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        alert('Thank you for subscribing!');
        setEmail(""); // Clear the input field
      } else {
        alert(data.message || 'Something went wrong.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form. Please try again.');
    }
  };

  return (
    <div className="joinletter">
      <div className="Newsletter">
        <p className="uptodate">Stay up to date</p>
        <p className="newst1">Join our Newsletter</p>
        <form onSubmit={handleSubmit}>
          <input
            className="emailbtn"
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email.."
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit" className="submit">Submit</button>
        </form>
        <p className="letterFooter">*You can unsubscribe anytime</p>
      </div>
      <div className="letterdesign">
        <div className="letterdesign1"></div>
        <div className="letterdesign2"></div>
        <div className="letterdesign3"></div>
      </div>
    </div>
  );
}

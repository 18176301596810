import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import "./TDSBlendingCalculator.css";

function TDSBlendingCalculator() {
  const [samples, setSamples] = useState([{ quantity: 0, tds: 0 }]);
  const [results, setResults] = useState(null);
  const [showDownload, setShowDownload] = useState(false); // Initially false
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "https://saffair.in/assets/logo.png";
    img.onload = () => setLogoLoaded(true);

    const mediaQuery = window.matchMedia("(max-width: 600px)");

    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
    };

    // Initial check
    handleMediaChange(mediaQuery);

    // Listen for changes
    mediaQuery.addListener(handleMediaChange);

    // Cleanup listener on unmount
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedSamples = [...samples];
    updatedSamples[index][field] = parseFloat(value) || 0;
    setSamples(updatedSamples);
  };

  const addSample = () => {
    if (samples.length < 12) {
      setSamples([...samples, { quantity: 0, tds: 0 }]);
    }
  };

  const removeSample = (index) => {
    if (samples.length > 1) {
      setSamples(samples.filter((_, i) => i !== index));
    }
  };

  const calculateTotals = () => {
    const totalQuantity = samples.reduce(
      (sum, sample) => sum + sample.quantity,
      0
    );
    const calculateTotalTDS = () =>
      samples.reduce(
        (sum, sample) => sum + sample.quantity * sample.tds * 1000,
        0
      );
    const blendedTDS = totalQuantity
      ? (calculateTotalTDS() / totalQuantity / 1000).toFixed(2)
      : 0;

    return {
      totalQuantity: totalQuantity.toFixed(2),
      blendedTDS: blendedTDS,
    };
  };

  const handleCalculate = () => {
    const { totalQuantity, blendedTDS } = calculateTotals();

    // Calculate the total mixture percentage
    const totalMixture = samples.reduce(
      (sum, sample) => sum + sample.quantity,
      0
    );

    if (totalMixture === 0) {
      // Mixture is 0, do not show results or download button
      setResults(false);
      setShowDownload(false);
      alert("Fill the necessary fields."); // Show the popup message
    } else {
      // Mixture is greater than 0, show results and download button
      setResults({ totalQuantity, blendedTDS });
      setShowDownload(true);
    }
  };

  const handleSaveAsImage = () => {
    // Select the buttons and hide them
    const buttons = document.querySelectorAll(
      ".add-sample-button, .calculate-button, .save-image-button, .remove-button"
    );
    buttons.forEach((button) => (button.style.display = "none"));

    // Select the element to capture
    const element = document.querySelector(".tab-content");
    if (element) {
      html2canvas(element, { useCORS: true }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "TDS Blending Calculator.png";
        link.click();

        // Show the buttons again
        buttons.forEach((button) => (button.style.display = ""));
      });
    }
  };

  return (
    <div className="water-consumption-body">
      <div>
        <h2 className="above-image-text-title">
          TDS Blending Calculator: Customizable Water Blending Tool for Optimal
          TDS Management
        </h2>
        <h2 className="above-image-text">
          This calculator allows users to blend water sources with different
          Total Dissolved Solids (TDS) levels to achieve a desired TDS
          concentration. By entering the TDS values and capacities of the water
          sources, the tool provides precise blending ratios, which are
          essential for industries requiring specific water quality standards.
          Whether for agricultural, industrial, or domestic water management,
          this unique tool offers a precise and customizable solution for
          maintaining optimal water quality.
        </h2>
        <div
          className="title-background-image-container"
          style={{ height: isMobile ? "120px" : "250px" }}
        >
          {" "}
          <img
            src="../assets/calculator-img/tds-blending.png"
            alt="Water Consumption Image"
            className="title-background-image"
          />
        </div>
        <div className="tab-content">
          <div className="container-without-image">
            <div className="title-container">
              <h2 className="title">TDS Blending Calculator</h2>
              {logoLoaded && (
                <img
                  src="https://saffair.in/assets/logo.png"
                  alt="Logo"
                  className="title-image"
                />
              )}
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Sample</th>
                    <th>
                      Quantity
                      <br />
                      (in m3)
                    </th>
                    <th>
                      Mixture
                      <br />
                      (in %)
                    </th>
                    <th>
                      TDS
                      <br />
                      (mg/l)
                    </th>
                    <th>
                      <center>
                        {" "}
                        <img
                          src="https://res.cloudinary.com/dmhpakrik/image/upload/v1724227797/Agevole/Saffair/delete.png"
                          width={"24px"}
                          alt="delete"
                        />
                      </center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {samples.map((sample, index) => (
                    <tr key={index}>
                      <td>Sample - {index + 1}</td>
                      <td data-label="Quantity (in M3)">
                        <input
                          type="number"
                          value={sample.quantity}
                          min="0"
                          onChange={(e) =>
                            handleInputChange(index, "quantity", e.target.value)
                          }
                        />
                      </td>
                      <td data-label="Mixture (in %)">
                        {(samples.reduce((sum, s) => sum + s.quantity, 0)
                          ? (sample.quantity /
                              samples.reduce((sum, s) => sum + s.quantity, 0)) *
                            100
                          : 0
                        ).toFixed(2)}
                      </td>
                      <td data-label="TDS (mg/l)">
                        <input
                          type="number"
                          value={sample.tds}
                          min="0"
                          onChange={(e) =>
                            handleInputChange(index, "tds", e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => removeSample(index)}
                          className="remove-button"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                  {results && (
                    <tr>
                      <td colSpan="1">Blend Water</td>
                      <td className="total-label">
                        {results.totalQuantity} m3
                      </td>
                      <td colSpan="1">Mixture: 100%</td>
                      <td colSpan="2" className="total-label">
                        TDS: {results.blendedTDS} mg/l
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <center>
              <button className="add-sample-button" onClick={addSample}>
                + Add Sample
              </button>
              <button onClick={handleCalculate} className="calculate-button">
                Calculate
              </button>
              {showDownload && (
                <button
                  onClick={handleSaveAsImage}
                  className="save-image-button"
                >
                  Download
                </button>
              )}
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TDSBlendingCalculator;

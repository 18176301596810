import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useLocation, Outlet } from "react-router-dom";
import { AppBar, Tabs, Tab, Container, Box } from "@mui/material";
// import WaterConsumptionCalculator from "./components/WaterConsumptionCalculator";
// import TDSBlendingCalculator from "./components/TDSBlendingCalculator";
// import PipelineSizeCalculator from "./components/PipelineSizeCalculator";
// import AirRequirementCalculator from "./components/AirRequirementCalculator";
// import PHCalculator from "./components/PHCalculator";
// import AQICalculator from "./components/AQICalculator";

import "./calc.css";

const Calculator = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const pathToIndex = {
      "/calculator/aqi": 0,
      "/calculator/water-consumption": 1,
      // "/calculator/air-req": 2,
      // "/calculator/pipeline-size": 3,
      "/calculator/tds-blending": 2,
      // "/calculator/ph-dosing": 5,
    };
    setValue(pathToIndex[location.pathname] || 0);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="calc">
      {/* <NavBar /> Fixed Navbar at the top */}
      <AppBar position="static" className="app-bar">
        {" "}
        {/* TabBar fixed below Navbar */}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          style={{ textDecorationColor: "#2196ba" }}
          classes={{ root: "tab-root", indicator: "tabs-indicator" }}
        >
          <Tab
            label="AQI"
            component={Link}
            to="/calculator/aqi"
            style={{ textTransform: "none" }}
            classes={{ root: "tab-root", selected: "tab-selected" }}
          />{" "}
          <Tab
            label="Water Consumption"
            component={Link}
            to="/calculator/water-consumption"
            style={{ textTransform: "none" }}
            classes={{ root: "tab-root", selected: "tab-selected" }}
          />
          {/* <Tab
            label="Air Req. for BOD"
            component={Link}
            to="/calculator/air-req"
            style={{ textTransform: "none" }}
            classes={{ root: "tab-root", selected: "tab-selected" }}
          />
          <Tab
            label="Pipe Line Size"
            component={Link}
            to="/calculator/pipeline-size"
            style={{ textTransform: "none" }}
            classes={{ root: "tab-root", selected: "tab-selected" }}
          /> */}
          <Tab
            label="TDS Blending"
            component={Link}
            to="/calculator/tds-blending"
            style={{ textTransform: "none" }}
            classes={{
              root: "tab-root",
              selected: "tab-selected",
            }}
          />
          {/* <Tab
            label="pH Dosing"
            component={Link}
            to="/calculator/ph-dosing"
            style={{ textTransform: "none" }}
            classes={{ root: "tab-root", selected: "tab-selected" }}
          /> */}
        </Tabs>
      </AppBar>
      <Container maxWidth="lg" className="calculator-container">
        <Box mt={3} className="calculator-content">
          <Outlet />
        </Box>
      </Container>
    </div>
  );
};

export default Calculator;

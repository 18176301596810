import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./AQICalculator.css";
import "./WaterConsumptionCalculator.css";

const AQICalculator = () => {
  const [pollutants, setPollutants] = useState({
    pm25: "",
    pm10: "",
    so2: "",
    no2: "",
    o3: "",
    co: "",
    lead: "",
    nh3: "",
  });

  // const [aqiType, setAqiType] = useState("");
  const [isPm25Only, setIsPm25Only] = useState(false);
  const [isCalculate, setCalculateAqi] = useState(false);
  const [allParametersAvailable, setAllParametersAvailable] = useState("Yes");
  const [calculationType, setCalculationType] = useState("Indian AQI");
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [showDownload, setShowDownload] = useState(false); // Initially false
  const [isMobile, setIsMobile] = useState(false);

  const [result, setResult] = useState({
    indianAQI: null,
    usAQI: null,
    indianPollutants: {},
    usPollutants: {},
  });

  useEffect(() => {
    const img = new Image();
    img.src = "https://saffair.in/assets/logo.png";
    img.onload = () => setLogoLoaded(true);

    const mediaQuery = window.matchMedia("(max-width: 600px)");

    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
    };

    // Initial check
    handleMediaChange(mediaQuery);

    // Listen for changes
    mediaQuery.addListener(handleMediaChange);

    // Cleanup listener on unmount
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  const handleSaveAsImage = () => {
    // Select the buttons and hide them
    const buttons = document.querySelectorAll(
      ".calculate-button-aqi-calculator, .save-image-button, .aqi-calculator-input"
    );
    buttons.forEach((button) => (button.style.display = "none"));

    // Select the element to capture
    const element = document.querySelector(".tab-content");
    if (element) {
      html2canvas(element, { useCORS: true }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "aqi-calculator.png";
        link.click();

        // Show the buttons again
        buttons.forEach((button) => (button.style.display = ""));
      });
    }
  };

  // PDF Download Code
  // const handleSaveAsPDF = () => {
  //   // Select the buttons and hide them
  //   const buttons = document.querySelectorAll(
  //     ".calculate-button-aqi-calculator, .save-image-button, .aqi-calculator-input"
  //   );
  //   buttons.forEach((button) => (button.style.display = "none"));

  //   // Select the element to capture
  //   const element = document.querySelector(".tab-content");
  //   if (element) {
  //     html2canvas(element, { useCORS: true }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const pageWidth = pdf.internal.pageSize.getWidth();
  //       const pageHeight = pdf.internal.pageSize.getHeight();

  //       const imgWidth = canvas.width;
  //       const imgHeight = canvas.height;

  //       const scaleFactor = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
  //       const scaledWidth = imgWidth * scaleFactor;
  //       const scaledHeight = imgHeight * scaleFactor;

  //       const xPos = (pageWidth - scaledWidth) / 2;
  //       const yPos = (pageHeight - scaledHeight) / 2;
  //       pdf.addImage(imgData, "PNG", xPos, yPos, scaledWidth, scaledHeight);

  //       pdf.save("aqi-calculator.pdf");

  //       // Show the buttons again
  //       buttons.forEach((button) => (button.style.display = ""));
  //     });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPollutants((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleParametersChange = (event) => {
    const value = event.target.value;
    setAllParametersAvailable(value);
    setShowDownload(false); // Show the download button after calculation

    pollutants.pm10 = "";
    pollutants.so2 = "";
    pollutants.no2 = "";
    pollutants.o3 = "";
    pollutants.co = "";
    pollutants.lead = "";
    pollutants.nh3 = "";

    setCalculateAqi(false);
  };

  const handleCalculationTypeChange = (e) => {
    setCalculationType(e.target.value);
  };

  const validateInputs = () => {
    const requiredFields = ["pm25"];

    if (allParametersAvailable === "Yes") {
      if (calculationType === "Indian AQI") {
        requiredFields.push("pm10", "so2", "no2", "o3", "co", "lead", "nh3");
      } else if (calculationType === "US AQI") {
        requiredFields.push("pm10", "so2", "no2", "o3", "co");
      } else if (calculationType === "Both AQI") {
        requiredFields.push("pm10", "so2", "no2", "o3", "co", "lead", "nh3");
      }
    }

    for (let field of requiredFields) {
      if (!pollutants[field]) {
        alert("Fill the necessary fields.");
        return false;
      }
    }
    return true;
  };

  const calculateAQI = () => {
    if (!validateInputs()) {
      return;
    }

    setShowDownload(true); // Show the download button after calculation

    let indianAQI = 0; // Replace with real calculations
    let usAQI = 0; // Replace with real calculations

    let indianPollutants = {
      "pm2.5": {
        value: pollutants.pm25,
        unit: "µg/m³",
        aqi: Math.round(calculateIndianPM25AQI(pollutants.pm25)),
      },
      pm10: {
        value: pollutants.pm10,
        unit: "µg/m³",
        aqi: Math.round(calculateIndianPM10AQI(pollutants.pm10)),
      },
      so2: {
        value: pollutants.so2,
        unit: "ppb",
        aqi: Math.round(calculateIndianSO2AQI(pollutants.so2)),
      },
      no2: {
        value: pollutants.no2,
        unit: "ppb",
        aqi: Math.round(calculateIndianNO2AQI(pollutants.no2)),
      },
      o3: {
        value: pollutants.o3,
        unit: "ppb",
        aqi: Math.round(calculateIndianO3AQI(pollutants.o3)),
      },
      co: {
        value: pollutants.co,
        unit: "ppm",
        aqi: Math.round(calculateIndianCOAQI(pollutants.co)),
      },
      lead: {
        value: pollutants.lead,
        unit: "µg/m³",
        aqi: Math.round(calculateIndianLeadAQI(pollutants.lead)),
      },
      nh3: {
        value: pollutants.nh3,
        unit: "µg/m³",
        aqi: Math.round(calculateIndianNH3AQI(pollutants.nh3)),
      },
    };

    let usPollutants = {
      "pm2.5": {
        value: pollutants.pm25,
        unit: "µg/m³",
        aqi: Math.round(calculateUSPM25AQI(pollutants.pm25)),
      },
      pm10: {
        value: pollutants.pm10,
        unit: "µg/m³",
        aqi: Math.round(calculateUSPM10AQI(pollutants.pm10)),
      },
      so2: {
        value: pollutants.so2,
        unit: "ppb",
        aqi: Math.round(calculateUSSO2AQI(pollutants.so2)),
      },
      no2: {
        value: pollutants.no2,
        unit: "ppb",
        aqi: Math.round(calculateUSNO2AQI(pollutants.no2)),
      },
      o3: {
        value: pollutants.o3,
        unit: "ppb",
        aqi: Math.round(calculateUSO3AQI(pollutants.o3)),
      },
      co: {
        value: pollutants.co,
        unit: "ppm",
        aqi: Math.round(calculateUSCOAQI(pollutants.co)),
      },
    };

    if (!isPm25Only || pollutants.pm25) {
      const pm25IndianAQI = calculateIndianPM25AQI(pollutants.pm25);
      const pm25USAQI = calculateUSPM25AQI(pollutants.pm25);
      if (pm25IndianAQI !== "Out of range")
        indianAQI = Math.max(indianAQI, pm25IndianAQI);
      if (pm25USAQI !== "Out of range") usAQI = Math.max(usAQI, pm25USAQI);
    }

    if (!isPm25Only) {
      const pm10IndianAQI = calculateIndianPM10AQI(pollutants.pm10);
      const pm10USAQI = calculateUSPM10AQI(pollutants.pm10);
      if (pm10IndianAQI !== "Out of range")
        indianAQI = Math.max(indianAQI, pm10IndianAQI);
      if (pm10USAQI !== "Out of range") usAQI = Math.max(usAQI, pm10USAQI);

      const so2IndianAQI = calculateIndianSO2AQI(pollutants.so2);
      const so2USAQI = calculateUSSO2AQI(pollutants.so2);
      if (so2IndianAQI !== "Out of range")
        indianAQI = Math.max(indianAQI, so2IndianAQI);
      if (so2USAQI !== "Out of range") usAQI = Math.max(usAQI, so2USAQI);

      const no2IndianAQI = calculateIndianNO2AQI(pollutants.no2);
      const no2USAQI = calculateUSNO2AQI(pollutants.no2);
      if (no2IndianAQI !== "Out of range")
        indianAQI = Math.max(indianAQI, no2IndianAQI);
      if (no2USAQI !== "Out of range") usAQI = Math.max(usAQI, no2USAQI);

      const o3IndianAQI = calculateIndianO3AQI(pollutants.o3);
      const o3USAQI = calculateUSO3AQI(pollutants.o3);
      if (o3IndianAQI !== "Out of range")
        indianAQI = Math.max(indianAQI, o3IndianAQI);
      if (o3USAQI !== "Out of range") usAQI = Math.max(usAQI, o3USAQI);

      const coIndianAQI = calculateIndianCOAQI(pollutants.co);
      const coUSAQI = calculateUSCOAQI(pollutants.co);
      if (coIndianAQI !== "Out of range")
        indianAQI = Math.max(indianAQI, coIndianAQI);
      if (coUSAQI !== "Out of range") usAQI = Math.max(usAQI, coUSAQI);

      if (pollutants.lead) {
        const leadIndianAQI = calculateIndianLeadAQI(pollutants.lead);
        if (leadIndianAQI !== "Out of range")
          indianAQI = Math.max(indianAQI, leadIndianAQI);
      }

      if (pollutants.nh3) {
        const nh3IndianAQI = calculateIndianNH3AQI(pollutants.nh3);
        if (nh3IndianAQI !== "Out of range")
          indianAQI = Math.max(indianAQI, nh3IndianAQI);
      }
    }

    const resultData = {
      indianAQI: calculationType !== "US AQI" ? indianAQI : null,
      usAQI: calculationType !== "Indian AQI" ? usAQI : null,
      indianPollutants: calculationType !== "US AQI" ? indianPollutants : {},
      usPollutants: calculationType !== "Indian AQI" ? usPollutants : {},
    };

    setResult(resultData);
    setCalculateAqi(true);
  };

  // AQI Calculation Functions
  const calculateIndianPM25AQI = (value) => {
    if (value <= 30) return (value * 50) / 30;
    if (value <= 60) return ((value - 30) * 50) / 30 + 50;
    if (value <= 90) return ((value - 60) * 100) / 30 + 100;
    if (value <= 120) return ((value - 90) * 100) / 30 + 200;
    if (value <= 250) return ((value - 120) * 100) / 130 + 300;
    if (value <= 380) return ((value - 250) * 100) / 130 + 400;
    return "Out of range";
  };

  const calculateUSPM25AQI = (value) => {
    if (value <= 9) return (value * 50) / 9;
    if (value <= 35.4) return ((value - 9) * 50) / 23.4 + 50;
    if (value <= 55.4) return ((value - 35.4) * 50) / 20 + 100;
    if (value <= 150.4) return ((value - 55.4) * 50) / 95 + 150;
    if (value <= 250.4) return ((value - 150.4) * 100) / 100 + 200;
    if (value <= 350.4) return ((value - 250.4) * 100) / 100 + 300;
    if (value <= 500.4) return ((value - 350.4) * 100) / 150 + 400;
    return "Out of range";
  };

  const calculateIndianPM10AQI = (value) => {
    if (value <= 50) return (value * 50) / 50;
    if (value <= 100) return ((value - 50) * 50) / 50 + 50;
    if (value <= 250) return ((value - 100) * 100) / 150 + 100;
    if (value <= 350) return ((value - 250) * 100) / 100 + 200;
    if (value <= 430) return ((value - 350) * 100) / 80 + 300;
    if (value <= 550) return ((value - 430) * 100) / 120 + 400;
    return "Out of range";
  };

  const calculateUSPM10AQI = (value) => {
    if (value <= 54) return (value * 50) / 54;
    if (value <= 154) return ((value - 54) * 50) / 100 + 50;
    if (value <= 254) return ((value - 154) * 50) / 100 + 100;
    if (value <= 354) return ((value - 254) * 50) / 100 + 150;
    if (value <= 424) return ((value - 354) * 100) / 70 + 200;
    if (value <= 504) return ((value - 424) * 100) / 80 + 300;
    if (value <= 604) return ((value - 504) * 100) / 100 + 400;
    return 500;
  };

  const calculateIndianSO2AQI = (value) => {
    if (value <= 40) return (value * 50) / 40;
    if (value <= 80) return ((value - 40) * 50) / 40 + 50;
    if (value <= 380) return ((value - 80) * 100) / 300 + 100;
    if (value <= 800) return ((value - 380) * 100) / 420 + 200;
    if (value <= 1600) return ((value - 800) * 100) / 800 + 300;
    if (value <= 2500) return ((value - 1600) * 100) / 900 + 400;
    return "Out of range";
  };

  const calculateUSSO2AQI = (value) => {
    if (value <= 35) return (value * 50) / 35;
    if (value <= 75) return ((value - 35) * 50) / 40 + 50;
    if (value <= 185) return ((value - 75) * 50) / 110 + 100;
    if (value <= 304) return ((value - 185) * 50) / 119 + 150;
    if (value <= 604) return ((value - 304) * 100) / 300 + 200;
    if (value <= 804) return ((value - 604) * 100) / 200 + 300;
    if (value <= 1004) return ((value - 804) * 100) / 200 + 400;
    return "Out of range";
  };

  const calculateIndianNO2AQI = (value) => {
    if (value <= 40) return (value * 50) / 40;
    if (value <= 80) return ((value - 40) * 50) / 40 + 50;
    if (value <= 180) return ((value - 80) * 100) / 100 + 100;
    if (value <= 280) return ((value - 180) * 100) / 100 + 200;
    if (value <= 400) return ((value - 280) * 100) / 120 + 300;
    if (value <= 1000) return ((value - 400) * 100) / 600 + 400;
    return "Out of range";
  };

  const calculateUSNO2AQI = (value) => {
    if (value <= 53) return (value * 50) / 53;
    if (value <= 100) return ((value - 53) * 50) / 47 + 50;
    if (value <= 360) return ((value - 100) * 100) / 260 + 100;
    if (value <= 649) return ((value - 360) * 100) / 289 + 150;
    if (value <= 1249) return ((value - 649) * 100) / 600 + 200;
    if (value <= 1649) return ((value - 1249) * 100) / 400 + 300;
    if (value <= 2049) return ((value - 1649) * 100) / 400 + 400;
    return "Out of range";
  };

  const calculateIndianO3AQI = (value) => {
    if (value <= 50) return (value * 50) / 50;
    if (value <= 100) return ((value - 50) * 50) / 50 + 50;
    if (value <= 168) return ((value - 100) * 100) / 68 + 100;
    if (value <= 208) return ((value - 168) * 100) / 40 + 200;
    if (value <= 748) return ((value - 208) * 100) / 540 + 300;
    return "Out of range";
  };

  const calculateUSO3AQI = (value) => {
    if (value <= 54) return (value * 50) / 54;
    if (value <= 70) return ((value - 54) * 50) / 16 + 50;
    if (value <= 85) return ((value - 70) * 50) / 15 + 100;
    if (value <= 105) return ((value - 85) * 50) / 20 + 150;
    if (value <= 200) return ((value - 105) * 100) / 95 + 200;
    return "Out of range";
  };

  const calculateIndianCOAQI = (value) => {
    if (value <= 1) return (value * 50) / 1;
    if (value <= 2) return ((value - 1) * 50) / 1 + 50;
    if (value <= 10) return ((value - 2) * 100) / 8 + 100;
    if (value <= 17) return ((value - 10) * 100) / 7 + 200;
    if (value <= 34) return ((value - 17) * 100) / 17 + 300;
    if (value <= 50) return ((value - 34) * 100) / 16 + 400;
    return "Out of range";
  };

  const calculateUSCOAQI = (value) => {
    if (value <= 4.4) return (value * 50) / 4.4;
    if (value <= 9.4) return ((value - 4.4) * 50) / 5 + 50;
    if (value <= 12.4) return ((value - 9.4) * 50) / 3 + 100;
    if (value <= 15.4) return ((value - 12.4) * 50) / 3 + 150;
    if (value <= 30.4) return ((value - 15.4) * 100) / 15 + 200;
    if (value <= 40.4) return ((value - 30.4) * 100) / 10 + 300;
    if (value <= 50.4) return ((value - 40.4) * 100) / 10 + 400;
    return "Out of range";
  };

  const calculateIndianLeadAQI = (value) => {
    if (value <= 0.5) return (value * 50) / 0.5;
    if (value <= 1) return ((value - 0.5) * 50) / 0.5 + 50;
    if (value <= 2) return ((value - 1) * 100) / 1 + 100;
    if (value <= 3) return ((value - 2) * 100) / 1 + 200;
    if (value <= 3.5) return ((value - 3) * 100) / 0.5 + 300;
    if (value <= 4) return ((value - 3.5) * 100) / 0.5 + 400;
    return "Out of range";
  };

  const calculateIndianNH3AQI = (value) => {
    if (value <= 200) return (value * 50) / 200;
    if (value <= 400) return ((value - 200) * 50) / 200 + 50;
    if (value <= 800) return ((value - 400) * 100) / 400 + 100;
    if (value <= 1200) return ((value - 800) * 100) / 400 + 200;
    if (value <= 1800) return ((value - 1200) * 100) / 600 + 300;
    if (value <= 2400) return ((value - 1800) * 100) / 600 + 400;
    return "Out of range";
  };

  return (
    <div className="aqi-calculator">
      <div className="water-consumption-body">
        <div>
          <h2 className="above-image-text-title">
            AQI Calculator: Compare Indian and US Air Quality Indices with
            Pollutant-specific Analysis
          </h2>
          <h2 className="above-image-text">
            This AQI calculator allows users to input pollutant concentrations
            and instantly compare the resulting Air Quality Index (AQI) based on
            both Indian and US standards. By leveraging this unique tool, users
            can gain a deeper understanding of how pollutants like PM2.5, PM10,
            NO2, SO2, CO, and O3 impact overall air quality. Ideal for
            environmental professionals, health experts, and the general public,
            this innovative calculator aids in comprehending the health effects
            and environmental implications of pollution, while offering a rare
            side-by-side comparison of international AQI standards.
          </h2>
          <div
            className="title-background-image-container"
            style={{ height: isMobile ? "120px" : "250px" }}
          >
            <img
              src="../assets/calculator-img/aqi-image.png"
              alt="Water Consumption Image"
              className="title-background-image"
            />
          </div>
          <div className="tab-content">
            <div className="container-without-image">
              {" "}
              <div>
                <div className="title-container">
                  <h2 className="title">AQI Calculator</h2>
                  {logoLoaded && (
                    <img
                      src="https://saffair.in/assets/logo.png"
                      alt="Logo"
                      className="title-image"
                    />
                  )}
                </div>
                <div className="aqi-calculator-input">
                  <form>
                    <label>All Parameters Available? </label>
                    <select
                      value={allParametersAvailable}
                      onChange={handleParametersChange}
                    >
                      <option value="No">No. Only PM2.5 is available</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <label>AQI Calculation</label>
                    <select
                      value={calculationType}
                      onChange={handleCalculationTypeChange}
                    >
                      <option value="Indian AQI">Indian AQI</option>
                      <option value="US AQI">US AQI</option>
                      <option value="Both AQI">Both AQI</option>
                    </select>
                    {allParametersAvailable !== "Yes" && (
                      <div className="pollutants-grid">
                        <div className="input-container">
                          <label>PM2.5:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="pm25"
                              value={pollutants.pm25}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">µg/m³</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {allParametersAvailable === "Yes" && (
                      <div className="pollutants-grid">
                        <div className="input-container">
                          <label>PM2.5:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="pm25"
                              value={pollutants.pm25}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">µg/m³</span>
                          </div>
                        </div>

                        <div className="input-container">
                          <label>PM10:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="pm10"
                              value={pollutants.pm10}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">µg/m³</span>
                          </div>
                        </div>

                        <div className="input-container">
                          <label>SO2:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="so2"
                              value={pollutants.so2}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">ppb</span>
                          </div>
                        </div>

                        <div className="input-container">
                          <label>NO2:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="no2"
                              value={pollutants.no2}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">ppb</span>
                          </div>
                        </div>

                        <div className="input-container">
                          <label>O3:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="o3"
                              value={pollutants.o3}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">ppb</span>
                          </div>
                        </div>

                        <div className="input-container">
                          <label>CO:</label>
                          <div className="input-with-unit">
                            <input
                              type="number"
                              name="co"
                              value={pollutants.co}
                              onChange={handleChange}
                              min="0"
                              placeholder={isMobile ? "" : "Enter value"}
                            />
                            <span className="unit">ppm</span>
                          </div>
                        </div>

                        {calculationType !== "US AQI" && (
                          <>
                            <div className="input-container">
                              <label>Lead:</label>
                              <div className="input-with-unit">
                                <input
                                  type="number"
                                  name="lead"
                                  value={pollutants.lead}
                                  onChange={handleChange}
                                  min="0"
                                  placeholder={isMobile ? "" : "Enter value"}
                                />
                                <span className="unit">µg/m³</span>
                              </div>
                            </div>

                            <div className="input-container">
                              <label>NH3:</label>
                              <div className="input-with-unit">
                                <input
                                  type="number"
                                  name="nh3"
                                  value={pollutants.nh3}
                                  onChange={handleChange}
                                  min="0"
                                  placeholder={isMobile ? "" : "Enter value"}
                                />
                                <span className="unit">µg/m³</span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </form>
                </div>{" "}
                {isCalculate && (
                  <center>
                    <div>
                      <h2 className="aqi-calculator-text">
                        {result.indianAQI !== null &&
                          result.usAQI === null &&
                          "Indian AQI Calculation"}
                        {result.usAQI !== null &&
                          result.indianAQI === null &&
                          "US AQI Calculation"}
                        {result.indianAQI !== null &&
                          result.usAQI !== null &&
                          "Indian & US AQI Calculation"}
                      </h2>
                      <div className="table-container-aqi-calculator">
                        {Array.from(
                          new Set([
                            ...Object.keys(result.indianPollutants),
                            ...Object.keys(result.usPollutants),
                          ])
                        ).map((key) => {
                          const isIndianValueMissing =
                            !result.indianPollutants[key]?.value;
                          const isUSValueMissing =
                            !result.usPollutants[key]?.value;

                          return (
                            <div
                              className="grid-item--aqi-calculator"
                              key={key}
                            >
                              <div className="border-pollutant-name">
                                {" "}
                                <div className="pollutant-name">
                                  {key.toUpperCase()}
                                </div>
                              </div>

                              {result.indianAQI !== null &&
                                result.usAQI === null && (
                                  <div
                                    className={`border-pollutant-value ${
                                      isIndianValueMissing ? "missing" : null
                                    }`}
                                  >
                                    <div
                                      className={`pollutant-value ${
                                        isIndianValueMissing ? "missing" : null
                                      }`}
                                    >
                                      {result.indianPollutants[key]?.value ||
                                        "N/A"}{" "}
                                      {!isIndianValueMissing
                                        ? result.indianPollutants[key]?.unit
                                        : ""}
                                    </div>
                                  </div>
                                )}
                              {result.usAQI !== null &&
                                result.indianAQI === null && (
                                  <div
                                    className={`border-pollutant-value ${
                                      isUSValueMissing ? "missing" : null
                                    }`}
                                  >
                                    <div
                                      className={`pollutant-value ${
                                        isUSValueMissing ? "missing" : null
                                      }`}
                                    >
                                      {key.toUpperCase() === "O3"
                                        ? result.usPollutants[key]?.value *
                                          0.001
                                        : result.usPollutants[key]?.value ||
                                          "N/A"}{" "}
                                      {!isUSValueMissing
                                        ? key.toUpperCase() === "O3"
                                          ? "ppm"
                                          : result.usPollutants[key]?.unit
                                        : ""}
                                    </div>
                                  </div>
                                )}

                              {result.usAQI !== null &&
                                result.indianAQI !== null &&
                                isUSValueMissing !== "missing" && (
                                  <div>
                                    {" "}
                                    <div className="border-pollutant-value-middle-aqi">
                                      <div
                                        className={`pollutant-value ${
                                          isIndianValueMissing
                                            ? "missing"
                                            : null
                                        }`}
                                      >
                                        {!isIndianValueMissing ? "IND: " : ""}
                                        {result.indianPollutants[key]?.value ||
                                          "N/A"}{" "}
                                        {!isIndianValueMissing
                                          ? result.indianPollutants[key]?.unit
                                          : ""}
                                      </div>
                                    </div>
                                    <div className="border-pollutant-value-last">
                                      <div
                                        className={`pollutant-value-last ${
                                          isUSValueMissing ? "missing" : null
                                        }`}
                                      >
                                        {!isUSValueMissing ? "US: " : ""}
                                        {key.toUpperCase() === "O3"
                                          ? result.usPollutants[key]?.value *
                                            0.001
                                          : result.usPollutants[key]?.value ||
                                            "N/A"}{" "}
                                        {!isUSValueMissing
                                          ? key.toUpperCase() === "O3"
                                            ? "ppm"
                                            : result.usPollutants[key]?.unit
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </center>
                )}{" "}
                {showDownload && (
                  <div className="table-container-aqi-calculator">
                    <div className="output-section-aqi-calculator">
                      <table>
                        <tbody>
                          {result.indianAQI !== null && (
                            <tr>
                              <th>
                                <strong>Indian AQI</strong>
                              </th>
                              <td>{Math.round(result.indianAQI)}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>{" "}
                      <table>
                        <tbody>
                          {result.usAQI !== null && (
                            <tr>
                              <th>
                                <strong>US AQI</strong>
                              </th>
                              <td>{Math.round(result.usAQI)}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <center>
                  {" "}
                  <button
                    className="calculate-button-aqi-calculator"
                    onClick={calculateAQI}
                  >
                    Calculate AQI
                  </button>
                  {showDownload && (
                    <button
                      onClick={handleSaveAsImage}
                      className="save-image-button"
                    >
                      Download
                    </button>
                  )}
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AQICalculator;
